import { MessageDescriptor } from '@formatjs/intl/lib';
import { FormatXMLElementFn, Options as IntlMessageFormatOptions } from 'intl-messageformat';
import { ReactNode, useCallback } from 'react';
import { FormatDateOptions, FormatNumberOptions, IntlShape, PrimitiveType, useIntl } from 'react-intl';

import { DataObject } from 'utils/APIErrorCodes/types';
import { isAPIErrorCode } from 'utils/APIErrorCodes/utils';
import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import {
  AdsState,
  AdvertisingPlatformPrettyId,
  CurrencyPrettyId,
  DisplayPlatformPrettyId,
  FacebookCallToActionPrettyId,
} from 'typeDeclarations/graphql/nodes';
import { isNull, isRecordOfIntlPrimitiveTypes, isUndefined } from 'typeDeclarations/typeGuards';
import { logError } from 'utils/logging';
import { useAuth } from 'components/App/AuthProvider';
import { BASE_ADS_STATE_TO_VARIANT_MAP, NON_STAFF_ADS_STATE_TO_VARIANT_MAP } from 'utils/adStates';

export const DEFAULT_MISSING_TRANSLATION = 'MISSING_TRANSLATION';

const DEFAULT_PLACEHOLDER = '--';

type ErrorFormatterFunction<E extends APIErrorCode> = (data: DataObject<E>) => string;

type ErrorFormattersMap = {
  [K in APIErrorCode]?: ErrorFormatterFunction<K>;
};

interface FormatNullableNumberOptions extends FormatNumberOptions {
  placeholderString?: string;
}

export interface ExtendedIntlShape extends IntlShape {
  formatAdState: (state: AdsState) => string;
  formatAPIErrorCode: <E extends APIErrorCode>(args: {
    errorCode: E;
    data: DataObject<E>;
    fallbackMessage?: string;
  }) => string;
  formatBoolean: (bool: boolean) => string;
  formatCountry: (countryPrettyId: string) => string;
  formatFieldName: (fieldName: string) => string;
  formatGender: (genderPrettyId: string) => string;
  formatLanguage: (languagePrettyId: string) => string;
  formatModelType: (modelType: string) => string;
  formatAdvertisingPlatform: (advertisingPlatformPrettyId: AdvertisingPlatformPrettyId) => string;
  formatAoICategory: (categoryPrettyId: string) => string;
  formatCampaignGoal: (goalPrettyId: string, options?: { plural?: boolean }) => string;
  formatChildVertical: (childVerticalPrettyId: string) => string;
  formatCurrency: (currencyPrettyId: CurrencyPrettyId) => string;
  formatDeviceType: (deviceTypePrettyId: string) => string;
  formatDisplayPlatform: (displayPlatformPrettyId: DisplayPlatformPrettyId) => string;
  formatFacebookCallToAction: (callToActionPrettyId: FacebookCallToActionPrettyId) => string;
  formatMoneyValue: (value: string | number | null, currency: string | null) => string;
  formatNullableNumber: (value: number | null, opts?: FormatNullableNumberOptions) => string;
  formatPercentageValue: (value: string | number, opts?: Omit<FormatNumberOptions, 'unit'>) => string;
  formatSocialProviders: (provider: string) => string;
  formatVertical: (verticalPrettyId: string) => string;
  formatScheduleDateRange: (args: {
    opts?: FormatDateOptions;
    endDate: string | number | Date | null;
    startDate: string | number | Date | null;
  }) => string;
  formatTimeDuration: (value: number) => string;
  formatTimeRange: (from: string | number | Date, to: string | number | Date, opts?: FormatDateOptions) => string;
}

/**
 * Extends the useIntl hook from react-intl with custom formatting functions.
 */
export function useExtendedIntl(): ExtendedIntlShape {
  const intlShape = useIntl();
  const { formatDate, formatTime, formatMessage: baseFormatMessage, formatNumber } = useIntl();

  const { session } = useAuth();

  const formatMessage = useCallback<ExtendedIntlShape['formatMessage']>(
    <T extends ReactNode>(
      descriptor: MessageDescriptor,
      values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T>>,
      opts?: IntlMessageFormatOptions,
    ): string | T | (string | T)[] => {
      return baseFormatMessage(
        {
          defaultMessage: DEFAULT_MISSING_TRANSLATION,
          ...descriptor,
        },
        values,
        opts,
      );
    },
    [baseFormatMessage],
  );

  const formatBoolean = useCallback<ExtendedIntlShape['formatBoolean']>(
    (bool) => formatMessage({ id: `shared.${bool ? 'yes' : 'no'}` }),
    [formatMessage],
  );

  const formatFieldName = useCallback<ExtendedIntlShape['formatFieldName']>(
    (fieldName) => formatMessage({ id: `field-name.${fieldName}` }),
    [formatMessage],
  );

  const formatModelType = useCallback<ExtendedIntlShape['formatModelType']>(
    (modelType) => formatMessage({ id: `model-type.${modelType}`, defaultMessage: 'undefined' }),
    [formatMessage],
  );

  const formatAdState = useCallback<ExtendedIntlShape['formatAdState']>(
    (state) => {
      const stateVariant = session?.user.isStaff
        ? BASE_ADS_STATE_TO_VARIANT_MAP[state]
        : NON_STAFF_ADS_STATE_TO_VARIANT_MAP[state];

      return formatMessage({ id: `state-variant.${stateVariant}` });
    },
    [formatMessage, session],
  );

  const formatAPIErrorCode = useCallback<ExtendedIntlShape['formatAPIErrorCode']>(
    ({ data, errorCode, fallbackMessage = formatMessage({ id: 'shared.default-error-message' }) }) => {
      // helper function for formatting an error code and validating the intl values object.
      // this is to avoid code repetition.
      function formatErrorCode(errCode: APIErrorCode, values?: Record<string, unknown>): string {
        if (isUndefined(values)) {
          return formatMessage({
            id: `error.api.${errCode}`,
          });
        }

        if (isRecordOfIntlPrimitiveTypes(values)) {
          return formatMessage(
            {
              id: `error.api.${errCode}`,
            },
            values,
          );
        }

        logError(
          new Error(`The following provided values are not of type 'IntlPrimitiveType': ${JSON.stringify(values)}`),
        );

        return fallbackMessage;
      }

      // This map creates relations between error codes and formatting functions specific for each
      // error code
      const errorFormattersMap: ErrorFormattersMap = {
        [APIErrorCode.ModelOperationFailed]: (errorData) => {
          const errorsCatalog = new APIErrorCodesCatalog([{ extensions: errorData.error }]);
          const catalogedErrorCodes = errorsCatalog.getErrorCodes();

          // values do not matter in this case, the keys are the translated errors that will be
          // displayed to the user
          const errorList: { [key: string]: undefined } = {};

          catalogedErrorCodes.forEach((errCode) => {
            if (!isAPIErrorCode(errCode)) {
              errorList[fallbackMessage] = undefined;

              logError(new Error(`Unknown error code '${errCode}'`));
            } else {
              const errData = errorsCatalog.getErrorData(errCode);

              if (!errData) {
                errorList[fallbackMessage] = undefined;
              } else {
                errorList[
                  formatAPIErrorCode({
                    data: errData,
                    fallbackMessage,
                    errorCode: errCode,
                  })
                ] = undefined;
              }
            }
          });

          return Object.keys(errorList).join('; ');
        },
        [APIErrorCode.InvalidValueStringContainsInvalidCharacters]: (errorData) => {
          const { invalid_characters: invalidCharacters, name } = errorData;

          const joinedInvalidCharacters = invalidCharacters.map((char) => `"${char}"`).join(', ');

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueStringContainsInvalidCharacters, {
            fieldName,
            joinedInvalidCharacters,
          });
        },
        [APIErrorCode.InvalidValueWordStartsWithInvalidCharacters]: (errorData) => {
          const { invalid_characters: invalidCharacters, name } = errorData;

          const joinedInvalidCharacters = invalidCharacters.map((char) => `"${char}"`).join(', ');

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueWordStartsWithInvalidCharacters, {
            fieldName,
            joinedInvalidCharacters,
          });
        },
        [APIErrorCode.InvalidValueStringTooLong]: (errorData) => {
          const { name, max_length: maxLength } = errorData;

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueStringTooLong, {
            fieldName,
            maxLength,
          });
        },
        [APIErrorCode.InvalidValueStringTooShort]: (errorData) => {
          const { name, min_length: minLength } = errorData;

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValueStringTooShort, {
            fieldName,
            minLength,
          });
        },
        [APIErrorCode.UnfulfilledPinningPositions]: (errorData) => {
          const { field_name: name, empty_positions: emptyPositions, num_missing_pins: numMissingPins } = errorData;

          const fieldName = formatFieldName(name);
          const pluralEmptyPositions = emptyPositions.length > 1;
          const pluralNumMissingPins = numMissingPins > 1;

          const joinedEmptyPositions = emptyPositions.map((pos) => `"${pos}"`).join(', ');

          return formatErrorCode(APIErrorCode.UnfulfilledPinningPositions, {
            fieldName,
            numMissingPins,
            joinedEmptyPositions,
            pluralEmptyPositions,
            pluralNumMissingPins,
          });
        },
        [APIErrorCode.InvalidValueOutsideBounds]: (errorData) => {
          const {
            value,
            name: fieldName,
            min_value: minValue,
            max_value: maxValue,
            max_comparator: maxComparator,
            min_comparator: minComparator,
          } = errorData;

          const conditions: string[] = [];

          if (!isNull(minValue)) {
            conditions.push(formatMessage({ id: `comparator.${minComparator}` }, { value: minValue }));
          }

          if (!isNull(maxValue)) {
            conditions.push(formatMessage({ id: `comparator.${maxComparator}` }, { value: maxValue }));
          }

          let condition: string | null = null;

          if (conditions.length) {
            condition = conditions.join(` ${formatMessage({ id: 'shared.and' })} `);
          }

          const formattedFieldName = formatFieldName(fieldName);

          return formatErrorCode(APIErrorCode.InvalidValueOutsideBounds, {
            value,
            condition,
            fieldName: formattedFieldName,
          });
        },
        [APIErrorCode.MaxEntriesExceeded]: (errorData) => {
          const { field_name: fieldName, model_type: modelType, max_entries: maxEntries } = errorData;

          let type = 'none';
          const hasMaxEntries = maxEntries ?? false;

          const formattedFieldName = formatFieldName(fieldName);
          const formattedModelType = formatModelType(modelType);

          if (!isUndefined(formattedFieldName) && !isUndefined(formattedModelType)) {
            type = 'full';
          } else if (!isUndefined(formattedFieldName)) {
            type = 'fieldName';
          } else if (!isUndefined(formattedModelType)) {
            type = 'model';
          }

          return formatErrorCode(APIErrorCode.MaxEntriesExceeded, {
            type,
            maxEntries,
            hasMaxEntries,
            modelType: formattedModelType,
            fieldName: formattedFieldName,
          });
        },
        [APIErrorCode.InvalidValue]: (errorData) => {
          const { name, value } = errorData;

          const fieldName = formatFieldName(name);

          return formatErrorCode(APIErrorCode.InvalidValue, { fieldName, value });
        },
        [APIErrorCode.DuplicateValue]: (errorData) => {
          const { field_name: fieldName, model_type: modelType, container_type: containerType } = errorData;

          let type;
          let hasFieldName = fieldName !== 'id';
          const formattedModelType = formatModelType(modelType);
          const formattedContainerType = formatModelType(containerType);
          const formattedFieldName = formatFieldName(fieldName);

          if (formattedFieldName === 'undefined') {
            hasFieldName = false;
          }

          if (formattedModelType !== 'undefined') {
            type = 'model';
          }

          if (formattedContainerType !== 'undefined') {
            type = 'container';
          }

          if (formattedModelType !== 'undefined' && formattedContainerType !== 'undefined') {
            type = 'full';
          }

          return formatErrorCode(APIErrorCode.DuplicateValue, {
            type,
            hasFieldName: hasFieldName,
            fieldName: formattedFieldName,
            modelType: formattedModelType,
            containerType: formattedContainerType,
          });
        },
        [APIErrorCode.IncompatibleValues]: (errorData) => {
          const { field_names: fieldNames, field_values: fieldValues } = errorData;

          const formattedString = fieldNames
            .map((fieldName, i) => {
              const formattedFieldName = formatFieldName(fieldName);
              const fieldValue = fieldValues[i];
              return `${formattedFieldName}=${fieldValue}`;
            })
            .join(', ');

          return formatErrorCode(APIErrorCode.IncompatibleValues, { fieldsAndValues: formattedString });
        },
        [APIErrorCode.InvalidSymbolRepetition]: (errorData) => {
          const { invalid_substring: invalidSubstring } = errorData;

          return formatErrorCode(APIErrorCode.InvalidSymbolRepetition, {
            invalidSubstring,
          });
        },
      };

      // the cast is necessary otherwise the type of the data argument in the format function gets
      // butchered as an intersection of DataObject's
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const format = errorFormattersMap[errorCode] as ErrorFormatterFunction<typeof errorCode> | undefined;

      if (format) {
        return format(data);
      }

      // if a specific format function doesn't exist for the error code, fallback to the
      // formatErrorCode() function
      return formatErrorCode(errorCode, data as Record<string, unknown>);
    },
    [formatFieldName, formatMessage, formatModelType],
  );

  const formatNullableNumber = useCallback<ExtendedIntlShape['formatNullableNumber']>(
    (value, opts) => {
      if (isNull(value)) {
        return opts?.placeholderString ?? DEFAULT_PLACEHOLDER;
      }

      return formatNumber(value, opts);
    },
    [formatNumber],
  );

  const formatGender = useCallback<ExtendedIntlShape['formatGender']>(
    (genderPrettyId) => formatMessage({ id: `gender.${genderPrettyId.toLowerCase()}` }),
    [formatMessage],
  );

  const formatCountry = useCallback<ExtendedIntlShape['formatCountry']>(
    (countryPrettyId) => formatMessage({ id: `country.${countryPrettyId}` }),
    [formatMessage],
  );

  const formatDeviceType = useCallback<ExtendedIntlShape['formatDeviceType']>(
    (deviceTypePrettyId) => formatMessage({ id: `device-type.${deviceTypePrettyId}` }),
    [formatMessage],
  );

  const formatAoICategory = useCallback<ExtendedIntlShape['formatAoICategory']>(
    (categoryPrettyId) => formatMessage({ id: `area-of-interest-category.${categoryPrettyId}` }),
    [formatMessage],
  );

  const formatLanguage = useCallback<ExtendedIntlShape['formatLanguage']>(
    (languagePrettyId) => formatMessage({ id: `language.${languagePrettyId}` }),
    [formatMessage],
  );

  const formatCurrency = useCallback<ExtendedIntlShape['formatCurrency']>(
    (currencyPrettyId) => formatMessage({ id: `currency.${currencyPrettyId}` }),
    [formatMessage],
  );

  const formatVertical = useCallback<ExtendedIntlShape['formatVertical']>(
    (verticalPrettyId) => formatMessage({ id: `vertical.${verticalPrettyId}` }),
    [formatMessage],
  );

  const formatChildVertical = useCallback<ExtendedIntlShape['formatChildVertical']>(
    (childVerticalPrettyId) => formatMessage({ id: `child-vertical.${childVerticalPrettyId}` }),
    [formatMessage],
  );

  const formatCampaignGoal = useCallback<ExtendedIntlShape['formatCampaignGoal']>(
    (goalPrettyId, options) => {
      // not plural by default
      const plural = Boolean(options?.plural);
      return formatMessage(
        {
          id: `campaign-goal.${goalPrettyId !== 'clicks' ? 'leads' : goalPrettyId}`,
        },
        { plural },
      );
    },
    [formatMessage],
  );

  const formatTimeRange = useCallback<ExtendedIntlShape['formatTimeRange']>(
    (to, from, opts) => {
      const start = formatTime(to, opts);
      const end = formatTime(from, opts);

      return `${start} - ${end}`;
    },
    [formatTime],
  );

  const formatScheduleDateRange = useCallback<ExtendedIntlShape['formatScheduleDateRange']>(
    ({ startDate, endDate, opts }) => {
      const start = startDate ? formatDate(startDate, opts) : null;
      const end = endDate ? formatDate(endDate, opts) : null;

      return formatMessage(
        { id: 'schedule.date-range' },
        {
          start,
          end,
        },
      );
    },
    [formatDate, formatMessage],
  );

  const formatAdvertisingPlatform = useCallback<ExtendedIntlShape['formatAdvertisingPlatform']>(
    (advertisingPlatformPrettyId) =>
      formatMessage({
        id: `advertisingPlatform.${advertisingPlatformPrettyId}`,
      }),
    [formatMessage],
  );

  const formatFacebookCallToAction = useCallback<ExtendedIntlShape['formatFacebookCallToAction']>(
    (callToActionPrettyId) =>
      formatMessage({
        id: `facebook-call-to-action.${callToActionPrettyId}`,
      }),
    [formatMessage],
  );

  const formatSocialProviders = useCallback<ExtendedIntlShape['formatSocialProviders']>(
    (provider) =>
      formatMessage({
        id: `social-providers.${provider}`,
      }),
    [formatMessage],
  );

  const formatDisplayPlatform = useCallback<ExtendedIntlShape['formatDisplayPlatform']>(
    (displayPlatformPrettyId) =>
      formatMessage({
        id: `displayPlatform.${displayPlatformPrettyId}`,
      }),
    [formatMessage],
  );

  const formatPercentageValue = useCallback<ExtendedIntlShape['formatPercentageValue']>(
    (value, opts) =>
      formatNumber(Number(value), {
        style: 'unit',
        unit: 'percent',
        maximumFractionDigits: 2,
        ...opts,
      }),
    [formatNumber],
  );

  const formatMoneyValue = useCallback<ExtendedIntlShape['formatMoneyValue']>(
    (value, currency) => {
      if (isNull(value) || isNull(currency)) return DEFAULT_PLACEHOLDER;

      return formatNumber(Number(value), {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
      });
    },
    [formatNumber],
  );

  /**
   * @param {number} value Time duration in milliseconds
   */
  const formatTimeDuration = useCallback<ExtendedIntlShape['formatTimeDuration']>((value) => {
    const totalSeconds = Math.floor(value / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const ms = value % 1000;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}:${ms.toString().padStart(3, '0')}`;
  }, []);

  return {
    ...intlShape,
    formatAPIErrorCode,
    formatAdState,
    formatAdvertisingPlatform,
    formatAoICategory,
    formatBoolean,
    formatCampaignGoal,
    formatChildVertical,
    formatCountry,
    formatCurrency,
    formatDeviceType,
    formatDisplayPlatform,
    formatFacebookCallToAction,
    formatFieldName,
    formatGender,
    formatLanguage,
    formatMessage,
    formatModelType,
    formatMoneyValue,
    formatNullableNumber,
    formatPercentageValue,
    formatScheduleDateRange,
    formatSocialProviders,
    formatTimeDuration,
    formatTimeRange,
    formatVertical,
  };
}

import { AdsState } from 'typeDeclarations/graphql/nodes';
import { amber, grey, lightBlue, orange, red, teal } from '@mui/material/colors';
import { useAuth } from 'components/App/AuthProvider';
import { useCallback } from 'react';
import { getObjectKeys } from './getObjectKeys';

export type StateVariant =
  | 'draft'
  | 'paused'
  | 'running'
  | 'canceled'
  | 'archived'
  | 'problems'
  | 'notRunning' // non-staff
  | 'noneRunning' // staff
  | 'someRunning' // staff
  | 'someProblems' // staff
  | 'pendingReview'
  | 'someAdsRunning' // non-staff
  | 'pendingTrackingSetup' // non-staff
  | 'someRunningSomeProblems'; // staff

export const BASE_ADS_STATE_TO_VARIANT_MAP: Record<AdsState, StateVariant> = {
  ALL_DRAFT: 'draft',
  ALL_PAUSED: 'paused',
  ALL_RUNNING: 'running',
  ALL_ARCHIVED: 'archived',
  ALL_CANCELED: 'canceled',
  ALL_PROBLEMS: 'problems',
  NONE_RUNNING: 'noneRunning',
  SOME_RUNNING: 'someRunning',
  SOME_PROBLEMS: 'someProblems',
  ALL_PENDING_REVIEW: 'pendingReview',
  ALL_PENDING_TRACKING_SETUP: 'pendingTrackingSetup',
  SOME_RUNNING_SOME_PROBLEMS: 'someRunningSomeProblems',
};

export const NON_STAFF_ADS_STATE_TO_VARIANT_MAP: Record<AdsState, StateVariant> = {
  ...BASE_ADS_STATE_TO_VARIANT_MAP,
  NONE_RUNNING: 'notRunning',
  SOME_PROBLEMS: 'notRunning',
  SOME_RUNNING: 'someAdsRunning',
  SOME_RUNNING_SOME_PROBLEMS: 'someAdsRunning',
};

export const STATE_VARIANT_TO_COLOR: Record<StateVariant, Record<string, string>> = {
  draft: amber,
  running: teal,
  problems: red,
  archived: grey,
  canceled: grey,
  paused: amber,
  noneRunning: red,
  notRunning: red,
  someRunning: orange,
  someProblems: orange,
  someAdsRunning: teal,
  pendingReview: lightBlue,
  pendingTrackingSetup: red,
  someRunningSomeProblems: orange,
};

const BASE_AD_STATE_FILTERS_MAP: PartialRecord<StateVariant, AdsState[]> = {
  draft: ['ALL_DRAFT'],
  paused: ['ALL_PAUSED'],
  running: ['ALL_RUNNING'],
  canceled: ['ALL_CANCELED'],
  archived: ['ALL_ARCHIVED'],
  problems: ['ALL_PROBLEMS'],
  pendingReview: ['ALL_PENDING_REVIEW'],
};

const NON_STAFF_AD_STATE_FILTERS_MAP: PartialRecord<StateVariant, AdsState[]> = {
  ...BASE_AD_STATE_FILTERS_MAP,
  notRunning: ['SOME_PROBLEMS', 'NONE_RUNNING'],
  someAdsRunning: ['SOME_RUNNING', 'SOME_RUNNING_SOME_PROBLEMS'],
};

const STAFF_AD_STATE_FILTERS_MAP: PartialRecord<StateVariant, AdsState[]> = {
  ...BASE_AD_STATE_FILTERS_MAP,
  noneRunning: ['NONE_RUNNING'],
  someRunning: ['SOME_RUNNING'],
  someProblems: ['SOME_PROBLEMS'],
  someRunningSomeProblems: ['SOME_RUNNING_SOME_PROBLEMS'],
};

export function useAdStateFilters() {
  const { session } = useAuth();

  const adStateFiltersMap = session?.user.isStaff ? STAFF_AD_STATE_FILTERS_MAP : NON_STAFF_AD_STATE_FILTERS_MAP;

  const filterLabels = getObjectKeys(adStateFiltersMap);

  const getFilterValues = useCallback(
    (stateVariants: StateVariant[]) => {
      // No filters or all filters applied so it's more efficient to not filter by anything
      if (!stateVariants.length || stateVariants.length === filterLabels.length) {
        return undefined;
      }

      return stateVariants.flatMap((label) => adStateFiltersMap[label] ?? []);
    },
    [adStateFiltersMap, filterLabels],
  );

  return {
    filterLabels,
    getFilterValues,
  };
}

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { CSSProperties, FunctionComponent, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import {
  teamsColorPalette,
  hashNumberFromString,
} from 'components/Header/HeaderMenuToggle/HeaderMenuContent/TeamsMenu/utils';
import { UserNode, TeamNode } from 'typeDeclarations/graphql/nodes';
import { ErrorMessage } from 'shared/ErrorMessage';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

import { Avatar, AvatarProps } from '@mui/material';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`;

interface TeamAvatarProps extends AvatarProps {
  teamColor: string;
}

const StyledTeamAvatar = styled(({ teamColor, ...rest }: TeamAvatarProps) => <Avatar {...rest} />)`
  margin-left: -0.75rem;
  background-color: ${({ teamColor }) => teamColor};
`;

/**
 * Fragment and type created for the optimization purpose during the login process
 */

export interface UserTeamAvatarQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | 'ddtId' | 'email' | 'name' | 'picture'>;
  sessionTeam: Pick<TeamNode, 'id' | '_id' | 'name' | 'numUsers'>;
}

export const USER_TEAM_AVATAR_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment UserTeamAvatarQueryFragment on Query {
    sessionUser {
      id
      ddtId
      modified
      email
      name
      picture {
        id
        url
      }
    }
    sessionTeam {
      id
      modified
      _id
      name
      numUsers
    }
  }
`);

type UserTeamAvatarQueryData = UserTeamAvatarQueryFragmentData;

const USER_TEAM_AVATAR_QUERY = gql(/* GraphQL */ `
  query userTeamAvatarQuery {
    ...UserTeamAvatarQueryFragment
  }
`);

interface UserTeamAvatarProps {
  className?: string;
  style?: CSSProperties;
}

export const UserTeamAvatar: FunctionComponent<PropsWithChildren<UserTeamAvatarProps>> = ({ className }) => {
  const onError = useDefaultOnError();

  const { data, loading, error } = useQuery<UserTeamAvatarQueryData>(USER_TEAM_AVATAR_QUERY, { onError });

  if (loading) {
    return <Avatar />;
  }

  if (error || !data) {
    return <ErrorMessage />;
  }

  const { picture, email, name: userName } = data.sessionUser;

  const { numUsers, _id: teamPrettyId, name: teamName } = data.sessionTeam;

  const teamColorIndex = hashNumberFromString(teamPrettyId) % teamsColorPalette.length;
  const teamColor = teamsColorPalette[teamColorIndex];

  return (
    <StyledDiv className={className}>
      <Avatar src={picture?.url} className="user-avatar">
        {picture ? null : userName?.[0]?.toUpperCase() || email[0] || 'U'}
      </Avatar>
      {numUsers !== null && numUsers > 1 && (
        <StyledTeamAvatar className="team-avatar" teamColor={teamColor}>
          {teamName?.[0].toUpperCase() || email[0] || 'T'}
        </StyledTeamAvatar>
      )}
    </StyledDiv>
  );
};

import { FunctionComponent, useState, useCallback, FormEvent, PropsWithChildren } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { gql } from '__generated__/gql';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { StripeCardElement } from '@stripe/stripe-js';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useSnackbar } from 'notistack';

import { useUserTracking } from 'userTracking/useUserTracking';
import { isError } from 'typeDeclarations/typeGuards';
import { GraphQLObject, Connection, Node } from 'typeDeclarations/graphql/base-types';
import { logError } from 'utils/logging';
import { PaymentSourceStripePaymentSourceData } from 'shared/PaymentSources/sharedFragments';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { StripeCardInput } from 'shared/StripeCardInput';
import {
  TeamNode,
  BillingProfileNode,
  UserNode,
  StripePaymentSourceNode,
  PaymentSourceNode,
} from 'typeDeclarations/graphql/nodes';
import { CACHED_BILLING_PROFILE_QUERY, CachedBillingProfileSessionTeamData } from 'shared/PaymentSources/sharedQueries';
import { useSetPrimaryPaymentSource } from 'hooks/mutations/useSetPrimaryPaymentSource';
import { ThrowableStripeError } from 'utils/stripe';
import { STRIPE_PAYMENT_SOURCES_PAGE_SIZE } from 'shared/PaymentSources/constants';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { Stack } from '@mui/material';

const CREATE_STRIPE_PAYMENT_SOURCE_CLIENT_SECRET_MUTATION = gql(/* GraphQL */ `
  mutation createStripePaymentSourceClientSecret($input: FetchCreateStripeCardClientSecretInput!) {
    fetchCreateStripeCardClientSecret(input: $input) {
      clientSecret
    }
  }
`);

const CREATE_STRIPE_PAYMENT_SOURCE_VIA_INTENT_MUTATION = gql(/* GraphQL */ `
  mutation createStripePaymentSourceViaIntent(
    $input: CreateStripePaymentSourceCardViaIntentInput!
    $amount: Int!
    $cursor: String
  ) {
    createStripePaymentSourceCardViaIntent(input: $input) {
      stripePaymentSource {
        id
        modified
      }
    }
    sessionTeam(input: {}) {
      team {
        id
        modified
        billingProfile {
          id
          modified
          hasCreditLine
          primaryPaymentSource {
            id
            modified
            stripePaymentSource {
              id
              modified
              ...PaymentSourceStripePaymentSourceFragment
            }
          }
          otherStripeCardPaymentSources(first: $amount, after: $cursor, orderBy: "-created") {
            ...OtherPaymentSourcesStripePaymentSourceConnectionFragment
          }
        }
      }
    }
    sessionUser(input: {}) {
      user {
        id
        modified
        numStripeCardPaymentSources
        numStripeCardPaymentSourcesMax
      }
    }
  }
`);

interface CreateStripePaymentSourceClientSecretMutationPayload extends GraphQLObject {
  clientSecret: string;
}

interface CreateStripePaymentSourceClientSecretMutationResponse {
  fetchCreateStripeCardClientSecret: CreateStripePaymentSourceClientSecretMutationPayload;
}

interface CreateStripePaymentSourceClientSecretMutationVariables {
  input: Record<string, unknown>;
}

interface CreateStripePaymentSourceViaIntentMutationTeamPayload extends GraphQLObject {
  team: Pick<TeamNode, '__typename' | 'id' | 'modified'> & {
    billingProfile: Pick<BillingProfileNode, '__typename' | 'id' | 'modified' | 'hasCreditLine'> & {
      otherStripeCardPaymentSources: Connection<Node & { modified: string } & PaymentSourceStripePaymentSourceData>;
      primaryPaymentSource:
        | null
        | (Pick<PaymentSourceNode, 'id' | 'modified'> & {
            stripePaymentSource: Pick<StripePaymentSourceNode, 'id' | 'modified'> &
              PaymentSourceStripePaymentSourceData;
          });
    };
  };
}

interface CreateStripePaymentSourceViaIntentMutationUserPayload extends GraphQLObject {
  user: Pick<UserNode, 'id' | 'modified' | 'numStripeCardPaymentSources' | 'numStripeCardPaymentSourcesMax'>;
}

interface CreateStripePaymentSourceViaIntentMutationResponse {
  createStripePaymentSourceCardViaIntent: {
    stripePaymentSource: null | Pick<StripePaymentSourceNode, 'id' | 'modified'>;
  };
  sessionTeam: CreateStripePaymentSourceViaIntentMutationTeamPayload;
  sessionUser: CreateStripePaymentSourceViaIntentMutationUserPayload;
}

interface CreateStripePaymentSourceViaIntentMutationVariables {
  amount: number;
  cursor: string | null;
  input: {
    setupIntentId?: string;
  };
}

interface CreatePaymentSourceFormProps {
  className?: string;
  disabled?: boolean;
  setAsPrimary?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
}

export const CreatePaymentSourceForm: FunctionComponent<PropsWithChildren<CreatePaymentSourceFormProps>> = ({
  disabled,
  setAsPrimary = false,
  buttonSize = 'medium',
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const onError = useDefaultOnError();
  const { enqueueSnackbar } = useSnackbar();
  const { captureEvent } = useUserTracking();
  const { formatMessage } = useExtendedIntl();

  const [loading, setLoading] = useState(false);

  const { setPrimaryPaymentSource } = useSetPrimaryPaymentSource();

  const onReady = () => setLoading(false);

  const [createStripePaymentSourceClientSecretMutation] = useMutation<
    CreateStripePaymentSourceClientSecretMutationResponse,
    CreateStripePaymentSourceClientSecretMutationVariables
  >(CREATE_STRIPE_PAYMENT_SOURCE_CLIENT_SECRET_MUTATION);

  const [createStripePaymentSourceViaIntentMutation, { client }] = useMutation<
    CreateStripePaymentSourceViaIntentMutationResponse,
    CreateStripePaymentSourceViaIntentMutationVariables
  >(CREATE_STRIPE_PAYMENT_SOURCE_VIA_INTENT_MUTATION, {
    onCompleted: ({ createStripePaymentSourceCardViaIntent, sessionTeam }) => {
      if (!setAsPrimary) {
        return;
      }

      const { stripePaymentSource: createdStripePaymentSource } = createStripePaymentSourceCardViaIntent;
      const { primaryPaymentSource, hasCreditLine } = sessionTeam.team.billingProfile;

      if (!createdStripePaymentSource) {
        return;
      }

      if (!hasCreditLine && createdStripePaymentSource.id !== primaryPaymentSource?.stripePaymentSource.id) {
        setPrimaryPaymentSource(createdStripePaymentSource.id);
      }
    },
  });

  const createStripePaymentSource = useCallback(
    async (cardElement: StripeCardElement | null) => {
      setLoading(true);

      const mutationVariables: CreateStripePaymentSourceClientSecretMutationVariables = {
        input: {},
      };

      try {
        const {
          data: createStripePaymentSourceClientSecretMutationData,
          errors: createStripePaymentSourceClientSecretMutationErrors,
        } = await createStripePaymentSourceClientSecretMutation({ variables: mutationVariables });

        if (createStripePaymentSourceClientSecretMutationErrors) {
          throw new ApolloError({
            graphQLErrors: createStripePaymentSourceClientSecretMutationErrors,
          });
        }

        if (!createStripePaymentSourceClientSecretMutationData) {
          throw new Error('Error on createStripePaymentSourceClientSecretMutation: No client secret');
        }

        if (!stripe) {
          throw new Error('No stripe');
        }

        if (!cardElement) {
          throw new Error('No cardElement');
        }

        const { clientSecret } = createStripePaymentSourceClientSecretMutationData.fetchCreateStripeCardClientSecret;

        const { setupIntent, error: cardSetupError } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (cardSetupError) {
          const { type, message } = cardSetupError;

          if (type === 'validation_error') {
            setLoading(false);
            return;
          }

          if (type === 'card_error') {
            enqueueSnackbar(message, { variant: 'error' });
            setLoading(false);
            return;
          }

          if (type === 'invalid_request_error') {
            enqueueSnackbar(message, { variant: 'error' });
          }

          throw new ThrowableStripeError(cardSetupError);
        }

        if (!setupIntent) {
          throw new Error('Error on confirmCardSetup: No setupIntent');
        }

        const cachedBillingProfileData = client?.readQuery<CachedBillingProfileSessionTeamData>({
          query: CACHED_BILLING_PROFILE_QUERY,
        });

        if (!cachedBillingProfileData) {
          throw new Error('No cachedBillingProfileData for createStripePaymentSourceViaIntent mutation');
        }

        const { errors: createStripePaymentSourceViaIntentMutationErrors } =
          await createStripePaymentSourceViaIntentMutation({
            variables: {
              amount: STRIPE_PAYMENT_SOURCES_PAGE_SIZE,
              cursor: null,
              input: {
                setupIntentId: setupIntent.id,
              },
            },
          });

        if (createStripePaymentSourceViaIntentMutationErrors) {
          throw new ApolloError({ graphQLErrors: createStripePaymentSourceViaIntentMutationErrors });
        }

        cardElement.clear();
      } catch (err) {
        if (isError(err)) {
          onError(err);
          logError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      client,
      stripe,
      onError,
      enqueueSnackbar,
      createStripePaymentSourceViaIntentMutation,
      createStripePaymentSourceClientSecretMutation,
    ],
  );

  function onSubmitHandler(event: FormEvent) {
    event.preventDefault();

    if (elements) {
      const cardElement = elements.getElement(CardElement);
      createStripePaymentSource(cardElement);
    }
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <Stack
        columnGap={2.5}
        alignItems="center"
        rowGap={{
          xs: 2,
          md: 0,
        }}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <StripeCardInput onReady={onReady} disabled={loading || disabled} />
        <ProgressButton
          type="submit"
          loading={loading}
          size={buttonSize}
          disabled={!stripe}
          variant="contained"
          onClick={() => captureEvent({ eventName: 'addNewCard' })}
          sx={{
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
        >
          {formatMessage({ id: 'wallet-credit-card-form.submit-button' })}
        </ProgressButton>
      </Stack>
    </form>
  );
};

import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { REFERRAL_AMOUNT, REFERRAL_CURRENCY } from './constants';
import { BillingProfileNode, UserNode } from 'typeDeclarations/graphql/nodes';
import { Node } from 'typeDeclarations/graphql/base-types';
import { AnnouncementModal } from 'shared/AnnouncementModal';
import { ReactComponent as ReferralAnnouncementImage } from 'images/referral-announcement.svg';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Fragment and type created for the optimization purpose during the login process
 */
export interface ReferralAnnouncementQueryFragmentData {
  sessionUser: Pick<UserNode, 'referralsInfoAcknowledged'>;
  sessionTeam: Node & {
    billingProfile: Pick<BillingProfileNode, 'hasCreditLine'> & {
      paymentSourceCharges: {
        pageInfo: {
          hasNextPage: boolean;
        };
      };
    };
  };
}

export const REFERRAL_ANNOUNCEMENT_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment ReferralAnnouncementQueryFragment on Query {
    sessionUser {
      id
      modified
      referralsInfoAcknowledged
    }
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        hasCreditLine
        paymentSourceCharges(first: 0) {
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  }
`);

type ReferralAnnouncementQueryData = ReferralAnnouncementQueryFragmentData;

const REFERRAL_ANNOUNCEMENT_QUERY = gql(/* GraphQL */ `
  query referralAnnouncementQuery {
    ...ReferralAnnouncementQueryFragment
  }
`);

interface UpdateUserResponse {
  updateUser: Pick<UserNode, 'id' | 'modified' | 'referralsInfoAcknowledged'>;
}

interface UpdateUserVariables {
  input: {
    referralsInfoAcknowledged: boolean;
  };
}

const UPDATE_USER_MUTATION = gql(/* GraphQL */ `
  mutation updateUserReferralAnnouncementAcknowledged($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        modified
        referralsInfoAcknowledged
      }
    }
  }
`);

export const ReferralAnnouncement: FunctionComponent = () => {
  const onError = useDefaultOnError();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { formatMessage, formatNumber } = useExtendedIntl();

  const { error: queryError, loading: queryLoading } = useQuery<ReferralAnnouncementQueryData>(
    REFERRAL_ANNOUNCEMENT_QUERY,
    {
      onError: (e) => {
        setOpen(false);
        onError(e);
      },
      onCompleted: (queryData) => {
        const { referralsInfoAcknowledged } = queryData.sessionUser;
        const { hasCreditLine } = queryData.sessionTeam.billingProfile;
        const { hasNextPage: hasCharges } = queryData.sessionTeam.billingProfile.paymentSourceCharges.pageInfo;

        setOpen(!referralsInfoAcknowledged && (hasCreditLine || hasCharges));
      },
    },
  );

  const [updateUser, { loading: mutationLoading }] = useMutation<UpdateUserResponse, UpdateUserVariables>(
    UPDATE_USER_MUTATION,
    {
      onError: (err) => onError(err),
      onCompleted: () => setOpen(false),
    },
  );

  const updateUserHandler = async () => {
    await updateUser({ variables: { input: { referralsInfoAcknowledged: true } } });
  };

  const onInviteHandler = async () => {
    await updateUser({ variables: { input: { referralsInfoAcknowledged: true } } });
    navigate('/account-settings');
  };

  if (queryLoading) {
    return null;
  }

  if (queryError) {
    return null;
  }

  const money = formatNumber(REFERRAL_AMOUNT, {
    style: 'currency',
    currency: REFERRAL_CURRENCY,
    maximumSignificantDigits: 2,
  });

  const cardActions = (
    <>
      <Button size="small" color="primary" onClick={updateUserHandler} disabled={mutationLoading}>
        {formatMessage({ id: 'referral-announcement.no-button-text' })}
      </Button>
      <Button size="small" color="primary" variant="contained" onClick={onInviteHandler} disabled={mutationLoading}>
        {formatMessage({ id: 'referral-announcement.yes-button-text' })}
      </Button>
    </>
  );

  const cardContent = (
    <>
      <Typography gutterBottom variant="h4" component="h2">
        {formatMessage({ id: 'referral-announcement.heading' }, { money })}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {formatMessage({ id: 'referral-announcement.subtitle' }, { money })}
      </Typography>
      <StyledDiv>
        <ReferralAnnouncementImage />
      </StyledDiv>
    </>
  );

  return (
    <AnnouncementModal
      open={open}
      cardActions={cardActions}
      cardContent={cardContent}
      onBackdropClick={updateUserHandler}
    />
  );
};

import { FunctionComponent, PropsWithChildren } from 'react';
import { Wrapper } from './Wrapper';

import { IconButton, CircularProgress, IconButtonProps } from '@mui/material';

interface ProgressIconButtonProps extends IconButtonProps {
  loading: boolean;
  spinnerSize?: number;
}

export const ProgressIconButton: FunctionComponent<PropsWithChildren<ProgressIconButtonProps>> = ({
  loading,
  disabled,
  spinnerSize = 24,
  className,
  style,
  ...rest
}) => (
  <Wrapper className={className} style={style} spinnerSize={spinnerSize}>
    <IconButton disabled={loading || disabled} {...rest} size="large" />
    {loading && <CircularProgress size={spinnerSize} className="spinner" />}
  </Wrapper>
);

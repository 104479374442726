import { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { isNull } from 'typeDeclarations/typeGuards';
import { LocaleContext } from 'i18n/LocaleContext';
import { DEFAULT_LOCALE, SupportedLocale } from 'i18n/constants';
import { logError } from 'utils/logging';
import { polyfillLocale } from './polyfillLocale';

async function getIntlMessages(locale: SupportedLocale): Promise<Record<string, string>> {
  await polyfillLocale(locale);

  const { default: intlMessages } = (await import(`./translations/${locale}.ts`)) as {
    default: Record<string, string>;
  };

  return intlMessages;
}

export const LocalizedIntlProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { appLocale } = useContext(LocaleContext);

  const [error, setError] = useState<Error>();

  const [currentIntlMessages, setCurrentIntlMessages] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    getIntlMessages(appLocale).then(setCurrentIntlMessages).catch(setError);
  }, [appLocale]);

  if (error) throw error;
  if (isNull(currentIntlMessages)) return null;

  return (
    <IntlProvider locale={appLocale} messages={currentIntlMessages} defaultLocale={DEFAULT_LOCALE} onError={logError}>
      {children}
    </IntlProvider>
  );
};

import { FunctionComponent, SVGProps, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { SvgIconProps, Typography } from '@mui/material';

interface EmptyStateProps {
  svg:
    | FunctionComponent<PropsWithChildren<SvgIconProps>>
    | FunctionComponent<PropsWithChildren<SVGProps<SVGSVGElement>>>;
  text: string | JSX.Element;
  className?: string;
}
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;

  .placeholder-icon {
    max-width: 100%;
    height: 100%;
  }
`;

export const EmptyState: FunctionComponent<PropsWithChildren<EmptyStateProps>> = ({ svg: SVG, text, className }) => (
  <StyledWrapper className={className}>
    <SVG className="placeholder-icon" />
    <Typography align="center" component="span">
      {text}
    </Typography>
  </StyledWrapper>
);

import styled from '@emotion/styled';
import { Stack, Typography, Button, ButtonTypeMap, ButtonProps } from '@mui/material';
import { ElementType, ReactNode } from 'react';
import { DefaultThemeSVG, DefaultThemeSVGProps } from 'shared/DefaultThemeSVG';

export interface DefaultScreenProps<BT extends ElementType = ButtonTypeMap['defaultComponent']>
  extends DefaultThemeSVGProps {
  title?: ReactNode;
  renderTitle?: () => ReactNode;
  renderButton?: () => ReactNode;
  description?: string | ReactNode;
  renderDescription?: () => ReactNode;
  /**
   * @link https://mui.com/material-ui/guides/composition/#with-typescript
   */
  CTAButtonProps?: ButtonProps<BT, { component?: BT }>;
}

export function DefaultScreen<BT extends ElementType = ButtonTypeMap['defaultComponent']>({
  SVG,
  title,
  description,
  renderTitle,
  renderButton,
  CTAButtonProps,
  renderDescription,
}: DefaultScreenProps<BT>) {
  const StyledSVG = styled(SVG)`
    width: auto;
    height: auto;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      height: 500px;
    }
  `;

  const defaultScreenTitle = renderTitle?.() ?? <Typography variant="h4">{title}</Typography>;

  const defaultScreenDescription = renderDescription?.() ?? <Typography>{description}</Typography>;

  const defaultScreenButton =
    renderButton?.() ??
    (CTAButtonProps && (
      <Button {...CTAButtonProps} variant="contained" sx={{ width: 'fit-content' }}>
        {CTAButtonProps.children}
      </Button>
    ));

  return (
    <Stack gap={4} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
      <Stack rowGap={2} pt={{ md: 6 }}>
        {defaultScreenTitle}
        {defaultScreenDescription}
        {defaultScreenButton}
      </Stack>
      <DefaultThemeSVG SVG={StyledSVG} />
    </Stack>
  );
}

import { useReactiveVar } from '@apollo/client';
import { config } from 'appConfig';
import { settingsVar } from 'client/cache';
import { useCreateOrUpdateFrontendSetting } from 'hooks/mutations/useCreateUpdateFrontendSettings';
import { useCallback } from 'react';
import { isError, isObject, isUndefined } from 'typeDeclarations/typeGuards';
import { getObjectKeys } from 'utils/getObjectKeys';
import { logError } from 'utils/logging';
import { DEFAULT_APP_SETTINGS, Setting } from './constants';
import { SettingValue, AppSettingsMap } from './types';

export let APP_SETTINGS_MAP: undefined | PartialRecord<Setting, boolean>;

/**
 * Type guard that checks if a provided string is an APIErrorCode
 */
export function isAppSetting(setting: string): setting is Setting {
  if (isUndefined(APP_SETTINGS_MAP)) {
    const settingsMap: PartialRecord<Setting, boolean> = {};

    Object.values(Setting).forEach((ec) => {
      settingsMap[ec] = true;
    });

    APP_SETTINGS_MAP = settingsMap;
  }

  return setting in APP_SETTINGS_MAP;
}

interface SetSettingArgs<T extends Setting> {
  settingId: T;
  shouldSave?: boolean;
  value: SettingValue<T>;
}

export type SetSettingsMap = {
  [K in Setting]?: { value: SettingValue<K>; shouldSave?: boolean };
};

interface AppSettingsReturnValue {
  setSettings: (args: SetSettingsMap) => void;
  setSetting: <T extends Setting>(args: SetSettingArgs<T>) => void;
  getSettingValue: <T extends Setting>(settingId: T) => SettingValue<T> | undefined;
}

export function useAppSettings(): AppSettingsReturnValue {
  const settings = useReactiveVar(settingsVar);
  const [createOrUpdateFrontendAppSetting] = useCreateOrUpdateFrontendSetting();

  const saveSetting = useCallback(
    <T extends Setting>(settingId: T, value: SettingValue<T>) => {
      if (isUndefined(value)) {
        logError(new Error(`Setting '${settingId}' must have a value to be saved`));
        return Promise.reject();
      }

      // Values must saved within an object for scalability reasons
      let objectValue: Record<string, unknown> | undefined;

      if (!isObject(value)) {
        objectValue = { value };
      } else {
        objectValue = value;
      }

      // Backend only accepts a JSON-serializable object as a string
      let serializedObject: string | undefined;

      try {
        // A value might not be serializable for some reason
        serializedObject = JSON.stringify(objectValue);
      } catch (e) {
        if (isError(e)) {
          logError(e);
        }
      }

      if (isUndefined(serializedObject)) return Promise.reject();

      const frontend = config.name;

      return createOrUpdateFrontendAppSetting({
        variables: {
          input: {
            frontend,
            name: settingId,
            value: serializedObject,
          },
        },
      });
    },
    [createOrUpdateFrontendAppSetting],
  );

  const getSettingValue = useCallback<AppSettingsReturnValue['getSettingValue']>(
    <T extends Setting>(settingId: T) => {
      const settingValue = settings[settingId] as SettingValue<T> | undefined;

      const fallbackValue = DEFAULT_APP_SETTINGS[settingId] ?? undefined;

      if (isObject(settingValue) && !Object.keys(settingValue).length) {
        return fallbackValue;
      }

      return settingValue ?? fallbackValue;
    },
    [settings],
  );

  const setSetting = useCallback<AppSettingsReturnValue['setSetting']>(
    ({ settingId, value, shouldSave = true }) => {
      settingsVar({
        ...settings,
        [settingId]: value,
      });

      if (shouldSave) {
        saveSetting(settingId, value);
      }
    },
    [saveSetting, settings],
  );

  const setSettings = useCallback<AppSettingsReturnValue['setSettings']>(
    (newSettings: SetSettingsMap) => {
      const saveSettingPromises: Promise<unknown>[] = [];

      const newSettingsMap: AppSettingsMap = {};

      getObjectKeys(newSettings).forEach((settingId) => {
        const settingSpecs = newSettings[settingId];

        if (settingSpecs) {
          const { value, shouldSave } = settingSpecs;

          // saving is considered to be the default behavior
          if (isUndefined(shouldSave) || shouldSave) {
            saveSettingPromises.push(saveSetting(settingId, value));
          }

          // Sorry but couldn't find any other better workaround
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
          newSettingsMap[settingId] = value as any;
        }
      });

      settingsVar({
        ...settings,
        ...newSettingsMap,
      });

      Promise.allSettled(saveSettingPromises);
    },
    [saveSetting, settings],
  );

  return {
    setSetting,
    setSettings,
    getSettingValue,
  };
}

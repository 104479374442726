import { FunctionComponent, ReactNode, useState } from 'react';

import { DEFAULT_LOCALE, SUPPORTED_LOCALES, SupportedLocale } from 'i18n/constants';
import { LocaleContext } from './LocaleContext';

export function getAppDefaultLocale(): SupportedLocale {
  // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
  const browserLanguage = navigator.language;

  // slicedLocale "de-DE" will become "de"
  const slicedBrowserLanguage = browserLanguage.slice(0, 2);

  const supportedLocale = SUPPORTED_LOCALES.find((locale) => locale.slice(0, 2) === slicedBrowserLanguage);

  return supportedLocale ?? DEFAULT_LOCALE;
}

export const LocaleProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [appLocale, setAppLocale] = useState<SupportedLocale>(getAppDefaultLocale());

  return <LocaleContext.Provider value={{ appLocale, setAppLocale }}>{children}</LocaleContext.Provider>;
};

import { FunctionComponent, ReactNode, useState, PropsWithChildren } from 'react';

import { MiniDialog } from 'shared/MiniDialog';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { GlobalFormikState } from 'shared/GlobalFormikState';
import { DefaultDialog, DefaultDialogProps } from './DefaultDialog';

interface RenderProps {
  handleUnsavedChanges: () => void;
}

export interface DefaultDialogFormProps extends Omit<DefaultDialogProps, 'renderContent' | 'renderActions'> {
  renderContent?: (props: RenderProps) => ReactNode;
  renderActions?: (props: RenderProps) => ReactNode;
}

export const DefaultFormDialog: FunctionComponent<PropsWithChildren<DefaultDialogFormProps>> = ({
  onClose,
  renderContent,
  renderActions,
  TransitionProps,
  dialogTitleProps,
  ...remainingDialogProps
}) => {
  const { formatMessage } = useExtendedIntl();
  const [isUnsavedChangesDialogOpen, setIsUnsaveChangesDialogOpen] = useState(false);

  function handleUnsavedChanges() {
    // setter for unsaved changes Dialog
    if (GlobalFormikState.dirty) setIsUnsaveChangesDialogOpen(true);
    // setter for main Dialog
    else onClose?.();
  }

  let renderDialogContent: DefaultDialogProps['renderContent'];
  if (renderContent) {
    renderDialogContent = () => renderContent({ handleUnsavedChanges });
  }

  let renderDialogActions: DefaultDialogProps['renderActions'];
  if (renderActions) {
    renderDialogActions = () => renderActions({ handleUnsavedChanges });
  }

  return (
    <>
      <DefaultDialog
        onClose={handleUnsavedChanges}
        renderContent={renderDialogContent}
        renderActions={renderDialogActions}
        TransitionProps={{
          ...TransitionProps,
          onExit: (node) => {
            setIsUnsaveChangesDialogOpen(false);
            TransitionProps?.onExit?.(node);
          },
        }}
        {...remainingDialogProps}
      />
      <MiniDialog
        variant="warning"
        onClick={onClose}
        open={isUnsavedChangesDialogOpen}
        onClose={() => setIsUnsaveChangesDialogOpen(false)}
        secondaryButtonText={formatMessage({ id: 'shared.cancel' })}
        title={formatMessage({ id: 'shared.discard-dialog-title' })}
        primaryButtonText={formatMessage({ id: 'shared.discard-dialog-button' })}
      />
    </>
  );
};

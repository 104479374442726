import { FunctionComponent, PropsWithChildren } from 'react';

import { Chip, ChipProps } from '@mui/material';

export type DefaultChipProps = ChipProps;

export const DefaultChip: FunctionComponent<PropsWithChildren<DefaultChipProps>> = ({ sx, label, ...rest }) => (
  <Chip
    size="small"
    label={label}
    title={label?.toString()}
    sx={{
      border: 1,
      borderRadius: 1,
      fontWeight: 'bold',
      lineHeight: '14px',
      letterSpacing: '0.4px',
      fontSize: 'caption.fontSize',
      ...sx,
    }}
    {...rest}
  />
);

import { FunctionComponent, PropsWithChildren } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const AccountArrowIconRight: FunctionComponent<PropsWithChildren<SvgIconProps>> = (props) => (
  <SvgIcon {...props}>
    <path d="M19 20.99V18.99H15V17H19V15L22 18L19 20.99ZM10 4C12.2091 4 14 5.79086 14 8C14 10.2091 12.2091 12 10 12C7.79086 12 6 10.2091 6 8C6 5.79086 7.79086 4 10 4ZM10 14C11.1546 14 12.252 14.1223 13.2434 14.3424C12.4637 15.3549 12 16.6233 12 18C12 18.7013 12.1203 19.3744 12.3414 20H2V18C2 15.7909 5.58172 14 10 14Z" />
  </SvgIcon>
);

export default AccountArrowIconRight;

import { FunctionComponent, ReactNode, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { Modal, Backdrop, Fade, Card, CardActions, CardContent, Divider } from '@mui/material';

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaper-root {
    outline: none;
  }
`;

const StyledCard = styled(Card)`
  min-width: 60vw;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

interface AnnouncementModalProps {
  cardActions: ReactNode;
  cardContent: ReactNode;
  open: boolean;
  onBackdropClick?: () => void;
}

export const AnnouncementModal: FunctionComponent<PropsWithChildren<AnnouncementModalProps>> = ({
  cardActions,
  cardContent,
  open,
  onBackdropClick,
}) => (
  <StyledModal
    aria-labelledby="announcement-modal"
    aria-describedby="announcement-modal"
    open={open}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    onBackdropClick={onBackdropClick}
  >
    <Fade in={open}>
      <StyledCard square>
        <CardContent>{cardContent}</CardContent>
        <Divider />
        <StyledCardActions>{cardActions}</StyledCardActions>
      </StyledCard>
    </Fade>
  </StyledModal>
);

import { ApolloError } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserNode } from 'typeDeclarations/graphql/nodes';
import { useEffect, useState } from 'react';
import { isError } from 'typeDeclarations/typeGuards';
import { config } from 'appConfig';
import { useLogoutUser } from 'hooks/useLogoutUser';
import { resetTracking } from 'userTracking/useUserTracking';
import { AllUserThemesFragmentData } from 'graphql/fragments';

/**
 * Fragment and type created for the optimization purpose during the login process
 */

/**
 * Interface created for the
 * query fragment REDIRECT_USER_THEME_PARTNER_QUERY_FRAGMENT.
 */
export type RedirectUserThemePartnerQueryFragmentData = AllUserThemesFragmentData & {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'isStaff' | 'theme'>;
};
/**
 * Fragment created to return the current user of the session,
 * in addition, to the user themes available from the system.
 */
export const REDIRECT_USER_THEME_PARTNER_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment RedirectUserThemePartnerQueryFragment on Query {
    sessionUser {
      id
      modified
      isStaff
      theme
    }
    ...AllUserThemesFragment
  }
`);

/**
 * Interface created for the
 *  gql_query REDIRECT_USER_THEME_PARTNER QUERY.
 */
type RedirectUserThemePartnerQueryData = RedirectUserThemePartnerQueryFragmentData;

/**
 * Query created to return the current user of the session,
 * in addition, to the user themes available from the system.
 */
const REDIRECT_USER_THEME_PARTNER_QUERY = gql(/* GraphQL */ `
  query redirectUserThemePartnerQuery {
    ...RedirectUserThemePartnerQueryFragment
  }
`);

interface UseRedirectUserThemePartnerReturnValue {
  loading: boolean;
  error?: Error;
}

/**
 * Hook responsible for check if the user's theme of the
 * current session is equivalent to that of the page he is
 * accessing.
 * @returns {boolean} loading - if is loading
 * @returns {Error| undefined} error   - error or undefined in function
 */
export function useRedirectUserThemePartner(): UseRedirectUserThemePartnerReturnValue {
  const [logoutUser, { client }] = useLogoutUser({
    onCompleted: () => {
      resetTracking();
    },
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    /**
     * Function responsible for locating and comparing whether the user's
     * theme of the current session is equivalent to that of the page he
     * is accessing. If both are the same, nothing happens. Otherwise, the
     * user is redirecting to the domain of the corresponding theme.
     * @function handleCheckUserTheme
     */
    async function handleCheckUserTheme(): Promise<void> {
      if (config.isLocalhost) {
        // do nothing on localhost
        setLoading(false);
        return;
      }

      try {
        const { data, errors } = await client.query<RedirectUserThemePartnerQueryData>({
          query: REDIRECT_USER_THEME_PARTNER_QUERY,
        });

        if (errors) {
          throw new ApolloError({ graphQLErrors: errors });
        }

        if (!data) {
          throw new Error('No data from query: REDIRECT_USER_THEME_PARTNER_QUERY');
        }

        const {
          allUserThemes,
          sessionUser: { isStaff, theme: userTheme },
        } = data;

        if (isStaff || !userTheme) {
          return;
        }

        const userThemeEdge = allUserThemes.edges.find(({ node }) => node._id === userTheme);

        if (!userThemeEdge) {
          throw new Error(`Could not find user UserThemeNode with _id: ${userTheme}`);
        }

        let userDomain = userThemeEdge.node.domain;

        // not proud of this. cannot use config here.
        if (import.meta.env.REACT_APP_ENVIRONMENT === 'release') {
          userDomain = userDomain.replace('develop', 'release');
        }

        const { hostname, pathname } = window.location;

        if (userDomain !== hostname) {
          await logoutUser();
          window.location.replace(`https://${userDomain}${pathname}`);
        }
      } catch (err) {
        if (isError(err)) {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    }

    handleCheckUserTheme();
  }, [client, logoutUser]);

  return {
    loading,
    error,
  };
}

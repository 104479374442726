import { FunctionComponent, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedNumberParts } from 'react-intl';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import { UserTeamAvatar } from 'shared/UserTeamAvatar';
import { PartnersThemeDecider } from 'shared/PartnersThemeDecider';
import { PermissionsViewDecider } from 'shared/PermissionsViewDecider';
import { APIPermission } from 'typeDeclarations/enums';
import { ErrorMessage } from 'shared/ErrorMessage';
import { NavigationMenuHeaderQueryFragmentData } from './fragments';

import { Link, Grid, Button, Typography, CircularProgress } from '@mui/material';

const StyledGrid = styled(Grid)`
  margin-top: 1.25rem;
  margin-bottom: 1rem;
`;

const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  max-width: 16rem;
`;

const StyledUserTeamAvatar = styled(UserTeamAvatar)`
  .team-avatar {
    border-color: ${({ theme }) => theme.palette.background.default};
  }
`;

const StyledButton = styled(Button)`
  min-width: 6.5rem;
  display: flex;
  text-align: center;
`;

type NavigationMenuHeaderQueryData = NavigationMenuHeaderQueryFragmentData;

const NAVIGATION_MENU_HEADER_QUERY = gql(/* GraphQL */ `
  query navigationMenuHeaderQuery {
    ...NavigationMenuHeaderQueryFragment
  }
`);

interface NavigationMenuHeaderProps {
  onAddMoneyClick: () => void;
  onLinkClick: () => void;
}

export const NavigationMenuHeader: FunctionComponent<PropsWithChildren<NavigationMenuHeaderProps>> = ({
  onAddMoneyClick,
  onLinkClick,
}) => {
  const { formatMessage } = useExtendedIntl();
  const { data, loading, error } = useQuery<NavigationMenuHeaderQueryData>(NAVIGATION_MENU_HEADER_QUERY);

  let teamNameTypography = null;
  let balanceContent = null;

  if (loading) {
    balanceContent = (
      <StyledSpan>
        <CircularProgress color="inherit" />
      </StyledSpan>
    );
  }

  if (error) {
    balanceContent = (
      <Typography align="center" color="inherit" variant="h4" component="p">
        <ErrorMessage />
      </Typography>
    );
  }

  if (data) {
    const { balance, currency, hasCreditLine, creditAvailable } = data.sessionTeam.billingProfile;

    let valueToFormat = 0;

    if (hasCreditLine && creditAvailable) {
      valueToFormat = Number(creditAvailable);
    } else if (balance) {
      valueToFormat = Number(balance);
    }

    teamNameTypography = (
      <StyledTypography noWrap align="center">
        {data.sessionTeam.name}
      </StyledTypography>
    );

    balanceContent = (
      <FormattedNumberParts value={valueToFormat} style="currency" currency={currency}>
        {(parts) => (
          <StyledSpan>
            {parts.map(({ type, value }) => {
              if (type === 'currency') {
                return (
                  <Typography align="center" color="inherit" variant="body1" component="span" key={`${type}-${value}`}>
                    {value}
                  </Typography>
                );
              }
              return (
                <Typography align="center" color="inherit" variant="h4" component="span" key={`${type}-${value}`}>
                  {value}
                </Typography>
              );
            })}
          </StyledSpan>
        )}
      </FormattedNumberParts>
    );
  }

  return (
    <StyledGrid container direction="column" alignItems="center" spacing={1}>
      <Grid item container justifyContent="center">
        <StyledUserTeamAvatar />
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <Grid item>{teamNameTypography}</Grid>
        <Grid item>
          <Link color="secondary" variant="caption" to="/team/dashboard" onClick={onLinkClick} component={RouterLink}>
            {formatMessage({ id: 'shared.overview' })}
          </Link>
        </Grid>
      </Grid>
      <PartnersThemeDecider
        // don't show the wallet and balance on fcr-be and yp-ca
        fcr-be={null}
        yp-ca={null}
        fallback={
          <>
            <Grid item>{balanceContent}</Grid>
            <PermissionsViewDecider requiredPermissions={[APIPermission.PerformChanges]}>
              <Grid item>
                <StyledButton variant="outlined" onClick={onAddMoneyClick} aria-label="top-up-wallet">
                  {formatMessage({ id: 'navigation-menu-header.button-text' })}
                </StyledButton>
              </Grid>
            </PermissionsViewDecider>
          </>
        }
      />
    </StyledGrid>
  );
};

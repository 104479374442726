import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { ChipTypeMap } from '@mui/material/Chip';
import { ElementType, JSX, ReactNode } from 'react';

import { useExtendedIntl } from 'hooks/useExtendedIntl';

export type DefaultAutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'openText' | 'closeText' | 'clearText' | 'noOptionsText' | 'renderInput'
> & {
  textFieldProps?: TextFieldProps;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
};

export function DefaultAutocomplete<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(props: DefaultAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>): JSX.Element {
  const { textFieldProps, ...remainingAutocompleteProps } = props;

  const { formatMessage } = useExtendedIntl();

  return (
    <Autocomplete
      id=""
      fullWidth
      clearOnBlur
      autoComplete
      autoHighlight
      clearOnEscape
      handleHomeEndKeys
      openText={formatMessage({ id: 'shared.open' })}
      clearText={formatMessage({ id: 'shared.clear' })}
      closeText={formatMessage({ id: 'default-autocomplete.close-text' })}
      noOptionsText={formatMessage({ id: 'default-autocomplete.no-options-text' })}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          variant="outlined"
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
          }}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
      {...remainingAutocompleteProps}
    />
  );
}

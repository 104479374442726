import { Container, ContainerProps } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

export const MainContainer: FunctionComponent<PropsWithChildren<Omit<ContainerProps, 'component'>>> = ({
  sx,
  ...rest
}) => (
  <Container
    component="main"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      pt: { xs: 2, sm: 3, md: 3 },
      pb: { xs: 14, sm: 16, md: 16 },
      ...sx,
    }}
    {...rest}
  />
);

import { Dispatch, forwardRef, MutableRefObject, SetStateAction } from 'react';

import { useSetSessionTeam } from 'hooks/mutations/useSetSessionTeam';
import { logError } from 'utils/logging';
import { NavigationMenu } from './NavigationMenu/NavigationMenu';
import { TeamsSubmenu } from './TeamsMenu/TeamsSubmenu/TeamsSubmenu';
import { ErrorMessage } from 'shared/ErrorMessage';
import { walletVar } from 'shared/Wallet/WalletDialog';

type Content = 'navigation' | 'teams';

interface HeaderMenuContentProps {
  onClose: () => void;
  content: string;
  setContent: Dispatch<SetStateAction<Content>>;
}

export const HeaderMenuContent = forwardRef<unknown, HeaderMenuContentProps>(
  ({ onClose, content, setContent }, ref) => {
    const [setSessionTeam] = useSetSessionTeam({
      onCompleted: () => {
        window.location.href = '/';

        /* FIXME; */
        /* This dont clean the reactiveVar, its a bug from Apollo */
        /* client.resetStore()
          .then(() => navigate("/team/dashboard", { replace: true }))
          .catch((err) => {
            logError(new Error(err));
            window.location.reload();
          }); */
      },
    });

    const onTeamClick = (teamId: string) => {
      setSessionTeam({
        variables: {
          input: {
            id: teamId,
          },
        },
      });

      onClose();
    };

    const handleOnAddMoneyClick = () => {
      walletVar({ isOpen: true });
    };

    switch (content) {
      case 'navigation':
        return (
          <NavigationMenu
            onLinkClick={onClose}
            onTeamClick={onTeamClick}
            onAddMoneyClick={handleOnAddMoneyClick}
            onMoreTeamsClick={() => setContent('teams')}
            ref={ref as MutableRefObject<HTMLElement>}
          />
        );
      case 'teams':
        return (
          <TeamsSubmenu
            ref={ref as MutableRefObject<HTMLDivElement>}
            onBackClick={() => setContent('navigation')}
            onTeamClick={onTeamClick}
          />
        );
      default:
        logError(new Error('Could not find content key.'));
        return <ErrorMessage />;
    }
  },
);

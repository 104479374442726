import { FunctionComponent, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import { FetchMoreWrapper } from 'shared/FetchMoreWrapper';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import { TeamUser } from 'shared/TeamForm/types';
import { TeamUserListItem } from './TeamUserListItem';
import { List } from '@mui/material';

const StyledList = styled(List)`
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-height: 15rem;
  }
`;

interface TeamUsersListProps {
  canEdit?: boolean;
  hasMore?: boolean;
  teamOwner: TeamUser;
  teamUsers: TeamUser[];
  fetchMore?: () => void;
  fetchingMore?: boolean;
  handleMakeAdmin: (owner: TeamUser) => void;
  handleDeleteTeamUser: (owner: TeamUser) => void;
}

export const TeamUsersList: FunctionComponent<PropsWithChildren<TeamUsersListProps>> = ({
  teamOwner,
  teamUsers,
  fetchMore,
  canEdit = false,
  hasMore = false,
  handleMakeAdmin,
  fetchingMore = false,
  handleDeleteTeamUser,
}) => {
  const { formatMessage } = useExtendedIntl();

  return (
    <StyledList>
      <TeamUserListItem
        isAdmin
        key={teamOwner.id}
        teamUser={teamOwner}
        handleMakeAdmin={handleMakeAdmin}
        handleDeleteTeamUser={handleDeleteTeamUser}
      />
      {teamUsers.map((teamUser) => (
        <TeamUserListItem
          canEdit={canEdit}
          key={teamUser.id}
          teamUser={teamUser}
          handleMakeAdmin={handleMakeAdmin}
          handleDeleteTeamUser={handleDeleteTeamUser}
        />
      ))}
      {hasMore && (
        <FetchMoreWrapper>
          <ProgressButton variant="text" onClick={fetchMore} loading={fetchingMore}>
            {formatMessage({ id: 'shared.load-more' })}
          </ProgressButton>
        </FetchMoreWrapper>
      )}
    </StyledList>
  );
};

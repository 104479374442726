import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { WbSunny as WbSunnyIcon, Brightness2 as Brightness2Icon } from '@mui/icons-material';

import { darkThemeVar, userThemeVar } from 'client/cache';
import { useReactiveVar } from '@apollo/client';
import { ReactComponent as AppLogo } from 'images/app-logo.svg';
import { config, isCurrentAppTheme, themeConfig } from 'appConfig';

import { HeaderMenuToggle } from './HeaderMenuToggle/HeaderMenuToggle';
import { EnvironmentLabel } from './EnvironmentLabel';
import { WalletBalance } from './WalletBalance';
import { PartnersThemeDecider } from 'shared/PartnersThemeDecider';
import { useFeatureFlags } from 'featureFlags/useFeatureFlags';
import { alpha, Stack, AppBar, Container, IconButton, Toolbar, Box } from '@mui/material';
import { isNull } from 'typeDeclarations/typeGuards';

export const APP_HEADER_ID = 'app-header';

export const Header: FunctionComponent = () => {
  const darkTheme = useReactiveVar(darkThemeVar);
  const userTheme = useReactiveVar(userThemeVar);
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <AppBar
      elevation={0}
      position="sticky"
      id={APP_HEADER_ID}
      sx={{
        background: (theme) =>
          isCurrentAppTheme('default') && userTheme === 'default'
            ? `linear-gradient(127deg, ${theme.palette.primary.main} 3.5%, #D44C80 145.64%)`
            : undefined,
      }}
    >
      <Toolbar disableGutters sx={{ minHeight: { xs: '4rem', md: '5rem' } }}>
        <Container>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Link to="/campaigns">
                <Box
                  sx={{
                    width: 'auto',
                    height: {
                      xs: '2rem',
                      md: '3rem',
                    },
                  }}
                >
                  {/* TODO: Investigate this. We do a nullability check, but `partnerTheme` is not typed as nullable. */}
                  {isNull(userTheme) ? (
                    <AppLogo width="100%" height="100%" />
                  ) : (
                    <img
                      width="100%"
                      height="100%"
                      alt="App Logo"
                      src={`${config.whitelabel_url}${userTheme}/images/${
                        themeConfig[userTheme].altLogo ? 'app-logo-alt' : 'app-logo'
                      }.svg`}
                    />
                  )}
                </Box>
              </Link>
              <EnvironmentLabel />
            </Stack>
            <Stack direction="row" alignItems="center" columnGap={3}>
              {isFeatureEnabled('dark-theme') && (
                <IconButton
                  edge="start"
                  onClick={() => darkThemeVar(!darkThemeVar())}
                  sx={{
                    color: ({ palette }) => palette.common.white,
                    ':hover': {
                      backgroundColor: ({ palette }) => alpha(palette.common.white, 0.15),
                    },
                  }}
                >
                  {!darkTheme ? <Brightness2Icon fontSize="small" /> : <WbSunnyIcon fontSize="small" />}
                </IconButton>
              )}
              <PartnersThemeDecider sitee={<WalletBalance />} fallback={<HeaderMenuToggle />} />
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

import { FunctionComponent } from 'react';
import { useQuery, gql } from '@apollo/client';
import { config } from 'appConfig';

import { TeamNode } from 'typeDeclarations/graphql/nodes';
import { Typography } from '@mui/material';

const { appEnvironment } = config;

interface IsDemoTeamQueryData {
  sessionTeam: Pick<TeamNode, 'id' | 'modified' | 'isDemo'>;
}

const IS_DEMO_TEAM_QUERY = gql(/* GraphQL */ `
  query isDemoTeamQuery {
    sessionTeam {
      id
      modified
      isDemo
    }
  }
`);

export const EnvironmentLabel: FunctionComponent = () => {
  const isProd = appEnvironment === 'prod';

  const { data } = useQuery<IsDemoTeamQueryData>(IS_DEMO_TEAM_QUERY, { skip: !isProd });

  let label: undefined | string;

  if (!isProd) {
    label = appEnvironment;
  }

  if (data?.sessionTeam.isDemo) {
    label = 'demo';
  }

  if (!label) return null;

  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        px: 0.5,
        fontWeight: 'bold',
        bgcolor: '#FCFDAF',
        textTransform: 'uppercase',
      }}
    >
      {label}
    </Typography>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { LinearProgress } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useExtendedIntl } from 'hooks/useExtendedIntl';
import {
  BingResponsiveSearchAdIssuesData,
  CampaignContextProviderCampaignFragmentData,
  FacebookCarouselAdIssuesData,
  GoogleResponsiveSearchAdIssuesData,
  InstagramCarouselAdIssuesData,
} from './fragments';
import { useRefreshWindow } from 'hooks/useRefreshWindow';
import { CampaignNode } from 'typeDeclarations/graphql/nodes';
import { exists, isNull, isUndefined } from 'typeDeclarations/typeGuards';
import { getDraftIssueFieldNamesDict, hasDraftIssues } from 'utils/draftIssues';
import { getObjectKeys } from 'utils/getObjectKeys';
import { LaunchCampaignButtonData } from '../StepsBar/LaunchCampaignButton/fragments';
import { CampaignStep } from '../useGetOrderedCampaignsSteps';
import { useGenerateCampaignSteps } from '../useGenerateCampaignSteps';
import { CampaignContext, CampaignContextValue, StepStatus } from './CampaignContext';
import { ErrorMessage } from 'shared/ErrorMessage';
import { parseJSONScalar } from 'utils/parseJsonScalar';
import { DEFAULT_CAMPAIGN_SETTINGS } from 'components/Campaign/Settings/constants';
import { CampaignSettingsMap } from 'components/Campaign/Settings/types';
import { StepsBarSkeleton } from '../StepsBar/StepsBar';
import { useCampaignBudgetWalletIssues } from 'components/Budget/hooks/useCampaignBudgetWalletIssues';
import { useAuth } from 'components/App/AuthProvider';

const CAMPAIGN_FIELD_NAME_TO_CAMPAIGN_STEP_MAP: PartialRecord<keyof CampaignNode, CampaignStep[]> = {
  targetingLocations: ['locations'],
  negativeGeocoderLocations: ['locations'],
  adPools: ['ads-and-keywords'],
  ads: ['ads', 'ads-and-keywords'],
  keywordRelations: ['keywords', 'ads-and-keywords'],
  allNegativeKeywords: ['keywords', 'ads-and-keywords'],
  legacyNegativeKeywords: ['keywords', 'ads-and-keywords'],
  targetingGroups: ['audiences'],
  goal: ['budget'],
  budget: ['budget'],
  scheduleEnd: ['budget'],
  costPerGoal: ['budget'],
  scheduleStart: ['budget'],
};

function mergeStatus(prevStatus: Partial<StepStatus>, newStatus: Partial<StepStatus>): StepStatus {
  const finalStatus: StepStatus = {
    touched: false,
    completed: false,
    hasBlockingIssues: false,
    hasNonBlockingIssues: false,
  };

  getObjectKeys(finalStatus).forEach((statusKey) => {
    finalStatus[statusKey] = (newStatus[statusKey] || prevStatus[statusKey]) ?? finalStatus[statusKey];
  });

  // completed steps imply they have been touched
  finalStatus.touched = finalStatus.touched || finalStatus.completed;

  return finalStatus;
}

const CAMPAIGN_PROVIDER_QUERY = gql(/* GraphQL */ `
  query campaignProviderQuery($campaignUglyId: ID, $campaignPrettyId: String) {
    campaign(id: $campaignUglyId, _id: $campaignPrettyId) {
      ...LaunchCampaignButtonFragment
      ...CampaignContextProviderCampaignFragment
    }
  }
`);

type CampaignProviderQueryVariables = {
  campaignUglyId?: string;
  campaignPrettyId?: string;
};

type CampaignProviderQueryData = {
  campaign: null | (LaunchCampaignButtonData & CampaignContextProviderCampaignFragmentData);
};

type RouteState = { campaignUglyId?: string };

export const CampaignProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { campaignPrettyId } = useParams();
  const refreshWindow = useRefreshWindow();

  const { session } = useAuth();

  const { formatMessage } = useExtendedIntl();

  const campaignUglyId = (location.state as RouteState)?.campaignUglyId;

  const budgetWalletIssues = useCampaignBudgetWalletIssues({ campaignPrettyId });
  const campaignSteps = useGenerateCampaignSteps({ campaignPrettyId, campaignUglyId });

  const variables: CampaignProviderQueryVariables = {};

  // There can be both, but we prioritize the ugly id from the state
  if (campaignUglyId) {
    variables.campaignUglyId = campaignUglyId;
  } else if (campaignPrettyId) {
    variables.campaignPrettyId = campaignPrettyId;
  }

  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
  } = useQuery<CampaignProviderQueryData, CampaignProviderQueryVariables>(CAMPAIGN_PROVIDER_QUERY, { variables });

  // @FIXME - This is pure hammering, we should rethink the campaign structure
  const regexp = /dashboard/;

  if (queryLoading || isUndefined(budgetWalletIssues)) {
    if (regexp.test(location.pathname)) {
      return <LinearProgress variant="indeterminate" />;
    } else {
      return <StepsBarSkeleton />;
    }
  }

  if (queryError || !queryData) return <ErrorMessage />;

  if (isUndefined(campaignSteps)) return null;

  const { campaign } = queryData;

  if (isNull(campaign)) return <Navigate to="/not-found" replace />;

  const {
    goal,
    budget,
    isDraft,
    advertiser,
    zeroKeywords,
    isPerformance,
    id: campaignId,
    externalCampaignId,
    draftStepsCompleted,
    zeroNormalLocations,
    zeroTargetingGroups,
    campaignProviderAds,
    shouldUseGoogleTagManager,
    draftIssues: campaignIssues,
  } = campaign;

  if (isNull(advertiser)) {
    refreshWindow(formatMessage({ id: 'shared.advertiser.refresh' }), 3000);
    return null;
  }

  // if the user is a staff member, or if the campaign is non-performance,
  // treat any draft issue as a blocking issue
  const onlyPerformanceInhibitingIssues = isPerformance && !session?.user.isStaff;

  /**
   * CAMPAIGN - LEVEL ISSUES
   */
  const campaignFieldNames = getDraftIssueFieldNamesDict(campaignIssues);

  campaignFieldNames.blocking.forEach((field) => {
    const affectedStepNames = CAMPAIGN_FIELD_NAME_TO_CAMPAIGN_STEP_MAP[field as keyof CampaignNode] ?? ['summary'];

    affectedStepNames.forEach((affectedStepName) => {
      const stepStatus = campaignSteps[affectedStepName];

      if (stepStatus) {
        campaignSteps[affectedStepName] = mergeStatus(stepStatus, { hasBlockingIssues: true });
      }
    });
  });

  if (!onlyPerformanceInhibitingIssues) {
    campaignFieldNames.nonBlocking.forEach((field) => {
      const affectedStepNames = CAMPAIGN_FIELD_NAME_TO_CAMPAIGN_STEP_MAP[field as keyof CampaignNode] ?? ['summary'];

      affectedStepNames.forEach((affectedStepName) => {
        const stepStatus = campaignSteps[affectedStepName];

        if (stepStatus) {
          campaignSteps[affectedStepName] = mergeStatus(stepStatus, { hasNonBlockingIssues: true });
        }
      });
    });
  }

  /**
   * LOCATIONS STEP STATUS
   */
  const currentLocationsStepStatus = campaignSteps.locations;

  if (currentLocationsStepStatus) {
    const campaignHasLocations = zeroNormalLocations.pageInfo.hasNextPage;

    campaignSteps.locations = mergeStatus(currentLocationsStepStatus, {
      touched: campaignHasLocations, // FIXME Or Has Negative Locations
      completed: campaignHasLocations,
    });
  }

  /**
   * ADS AND KEYWORDS STEP STATUS
   */
  const currentAdsAndKwsStepStatus = campaignSteps['ads-and-keywords'];

  if (currentAdsAndKwsStepStatus) {
    const campaignHasKws = zeroKeywords.pageInfo.hasNextPage;
    const campaignHasAds = campaignProviderAds.edges.length > 0;

    campaignSteps['ads-and-keywords'] = mergeStatus(currentAdsAndKwsStepStatus, {
      // FIXME Or has Negative keywords, Or Has extensions Or Has facebook special ad category
      touched: campaignHasAds || campaignHasKws,
      completed: campaignHasAds && campaignHasKws,
      hasBlockingIssues: campaignProviderAds.edges.some(({ node: adInterfaceNode }) => {
        const { __typename: adType, draftIssues: adIssues } = adInterfaceNode;

        let hasIssues = hasDraftIssues({
          draftIssues: adIssues,
          onlyPerformanceInhibitingIssues: true,
        });

        if (!hasIssues) {
          switch (adType) {
            case 'InstagramCarouselAdNode':
            case 'FacebookCarouselAdNode': {
              const { cards } = adInterfaceNode as FacebookCarouselAdIssuesData | InstagramCarouselAdIssuesData;

              hasIssues =
                hasIssues ||
                cards.edges.some(({ node: cardNode }) =>
                  hasDraftIssues({
                    draftIssues: cardNode.draftIssues,
                    onlyPerformanceInhibitingIssues,
                  }),
                );

              break;
            }

            case 'BingResponsiveSearchAdNode':
            case 'GoogleResponsiveSearchAdNode': {
              const { headlines, descriptions } = adInterfaceNode as
                | GoogleResponsiveSearchAdIssuesData
                | BingResponsiveSearchAdIssuesData;

              hasIssues =
                hasIssues ||
                headlines.edges.some(({ node: headlinesNode }) =>
                  hasDraftIssues({
                    draftIssues: headlinesNode.draftIssues,
                    onlyPerformanceInhibitingIssues,
                  }),
                );

              hasIssues =
                hasIssues ||
                descriptions.edges.some(({ node: descriptionNode }) =>
                  hasDraftIssues({
                    draftIssues: descriptionNode.draftIssues,
                    onlyPerformanceInhibitingIssues,
                  }),
                );

              break;
            }
          }
        }

        return hasIssues;
      }),
      hasNonBlockingIssues:
        !onlyPerformanceInhibitingIssues &&
        campaignProviderAds.edges.some(({ node: adInterfaceNode }) => {
          const { __typename: adType, draftIssues: adIssues } = adInterfaceNode;

          let hasIssues = hasDraftIssues({
            draftIssues: adIssues,
            onlyPerformanceInhibitingIssues: false,
          });

          if (!hasIssues) {
            switch (adType) {
              case 'InstagramCarouselAdNode':
              case 'FacebookCarouselAdNode': {
                const { cards } = adInterfaceNode as FacebookCarouselAdIssuesData | InstagramCarouselAdIssuesData;

                hasIssues =
                  hasIssues ||
                  cards.edges.some(({ node: cardNode }) =>
                    hasDraftIssues({
                      draftIssues: cardNode.draftIssues,
                      onlyPerformanceInhibitingIssues,
                    }),
                  );

                break;
              }

              case 'BingResponsiveSearchAdNode':
              case 'GoogleResponsiveSearchAdNode': {
                const { headlines, descriptions } = adInterfaceNode as
                  | GoogleResponsiveSearchAdIssuesData
                  | BingResponsiveSearchAdIssuesData;

                hasIssues =
                  hasIssues ||
                  headlines.edges.some(({ node: headlinesNode }) =>
                    hasDraftIssues({
                      draftIssues: headlinesNode.draftIssues,
                      onlyPerformanceInhibitingIssues,
                    }),
                  );

                hasIssues =
                  hasIssues ||
                  descriptions.edges.some(({ node: descriptionNode }) =>
                    hasDraftIssues({
                      draftIssues: descriptionNode.draftIssues,
                      onlyPerformanceInhibitingIssues,
                    }),
                  );

                break;
              }
            }
          }

          return hasIssues;
        }),
    });
  }

  /**
   * ADS STEP STATUS
   */
  const currentAdsStepStatus = campaignSteps.ads;

  if (currentAdsStepStatus) {
    const campaignHasAds = campaignProviderAds.edges.length > 0;

    campaignSteps.ads = mergeStatus(currentAdsStepStatus, {
      touched: campaignHasAds,
      completed: campaignHasAds,
      hasBlockingIssues: campaignProviderAds.edges.some(({ node: adInterfaceNode }) => {
        const { __typename: adType, draftIssues: adIssues } = adInterfaceNode;

        let hasIssues = hasDraftIssues({
          draftIssues: adIssues,
          onlyPerformanceInhibitingIssues: true,
        });

        if (!hasIssues) {
          switch (adType) {
            case 'InstagramCarouselAdNode':
            case 'FacebookCarouselAdNode': {
              const { cards } = adInterfaceNode as FacebookCarouselAdIssuesData | InstagramCarouselAdIssuesData;

              hasIssues =
                hasIssues ||
                cards.edges.some(({ node: cardNode }) =>
                  hasDraftIssues({
                    draftIssues: cardNode.draftIssues,
                    onlyPerformanceInhibitingIssues,
                  }),
                );

              break;
            }

            case 'BingResponsiveSearchAdNode':
            case 'GoogleResponsiveSearchAdNode': {
              const { headlines, descriptions } = adInterfaceNode as
                | GoogleResponsiveSearchAdIssuesData
                | BingResponsiveSearchAdIssuesData;

              hasIssues =
                hasIssues ||
                headlines.edges.some(({ node: headlinesNode }) =>
                  hasDraftIssues({
                    draftIssues: headlinesNode.draftIssues,
                    onlyPerformanceInhibitingIssues,
                  }),
                );

              hasIssues =
                hasIssues ||
                descriptions.edges.some(({ node: descriptionNode }) =>
                  hasDraftIssues({
                    draftIssues: descriptionNode.draftIssues,
                    onlyPerformanceInhibitingIssues,
                  }),
                );

              break;
            }
          }
        }

        return hasIssues;
      }),
      hasNonBlockingIssues:
        !onlyPerformanceInhibitingIssues &&
        campaignProviderAds.edges.some(({ node: adInterfaceNode }) => {
          const { __typename: adType, draftIssues: adIssues } = adInterfaceNode;

          let hasIssues = hasDraftIssues({
            draftIssues: adIssues,
            onlyPerformanceInhibitingIssues: false,
          });

          if (!hasIssues) {
            switch (adType) {
              case 'InstagramCarouselAdNode':
              case 'FacebookCarouselAdNode': {
                const { cards } = adInterfaceNode as FacebookCarouselAdIssuesData | InstagramCarouselAdIssuesData;

                hasIssues =
                  hasIssues ||
                  cards.edges.some(({ node: cardNode }) =>
                    hasDraftIssues({
                      draftIssues: cardNode.draftIssues,
                      onlyPerformanceInhibitingIssues,
                    }),
                  );

                break;
              }

              case 'BingResponsiveSearchAdNode':
              case 'GoogleResponsiveSearchAdNode': {
                const { headlines, descriptions } = adInterfaceNode as
                  | GoogleResponsiveSearchAdIssuesData
                  | BingResponsiveSearchAdIssuesData;

                hasIssues =
                  hasIssues ||
                  headlines.edges.some(({ node: headlinesNode }) =>
                    hasDraftIssues({
                      draftIssues: headlinesNode.draftIssues,
                      onlyPerformanceInhibitingIssues,
                    }),
                  );

                hasIssues =
                  hasIssues ||
                  descriptions.edges.some(({ node: descriptionNode }) =>
                    hasDraftIssues({
                      draftIssues: descriptionNode.draftIssues,
                      onlyPerformanceInhibitingIssues,
                    }),
                  );

                break;
              }
            }
          }

          return hasIssues;
        }),
    });
  }

  /**
   * KEYWORDS STEP STATUS
   */
  const currentKWsStepStatus = campaignSteps.keywords;

  if (currentKWsStepStatus) {
    const campaignHasKWs = zeroKeywords.pageInfo.hasNextPage;

    campaignSteps.keywords = mergeStatus(currentKWsStepStatus, {
      touched: campaignHasKWs,
      completed: campaignHasKWs,
    });
  }

  /**
   * TARGETING GROUPS STEP STATUS
   */
  const currentTGsStepStatus = campaignSteps.audiences;

  if (currentTGsStepStatus) {
    const campaignHasTGs = zeroTargetingGroups.pageInfo.hasNextPage;

    campaignSteps.audiences = mergeStatus(currentTGsStepStatus, {
      touched: campaignHasTGs,
      completed: campaignHasTGs,
    });
  }

  /**
   * BUDGET STEP STATUS
   */
  const currentBudgetStepStatus = campaignSteps.budget;

  if (budget && currentBudgetStepStatus) {
    const { blocking: blockingFieldNames, nonBlocking: nonBlockingFieldNames } = getDraftIssueFieldNamesDict(
      budget.draftIssues,
    );

    campaignSteps.budget = mergeStatus(currentBudgetStepStatus, {
      completed: !isNull(budget.amount),
      hasBlockingIssues: blockingFieldNames.length > 0,
      hasNonBlockingIssues:
        exists(budgetWalletIssues) || (!onlyPerformanceInhibitingIssues && nonBlockingFieldNames.length > 0),
    });
  }

  /**
   * Check if campaign can be published or not after checking
   * all blocking issues
   */
  const hasBlockingIssues =
    Object.values(campaignSteps).some((step) => step.hasBlockingIssues) ||
    hasDraftIssues({
      onlyPerformanceInhibitingIssues,
      draftIssues: advertiser.draftIssues,
    });

  /**
   * SUMMARY STEP
   */
  const currentSummaryStepStatus = campaignSteps.summary;

  if (currentSummaryStepStatus) {
    let isSummaryTouched = false;
    let totalNumberOfSteps = 0;
    let numberOfStepsCompleted = 0;
    let summaryHasBlockingIssues = false;
    let summaryHasNonBlockingIssues = false;

    getObjectKeys(campaignSteps).forEach((stepName) => {
      const stepStatus = campaignSteps[stepName];

      if (stepStatus && stepName !== 'summary') {
        const stepIsTouched = stepStatus.touched;
        const stepIsCompleted = stepStatus.completed;

        totalNumberOfSteps++;

        isSummaryTouched = isSummaryTouched || stepIsTouched;

        if (stepIsCompleted || stepIsTouched) {
          summaryHasBlockingIssues = summaryHasBlockingIssues || stepStatus.hasBlockingIssues;
          summaryHasNonBlockingIssues = summaryHasNonBlockingIssues || stepStatus.hasNonBlockingIssues;
        }

        if (stepIsCompleted) {
          numberOfStepsCompleted++;
        }
      }
    });

    campaignSteps.summary = mergeStatus(currentSummaryStepStatus, {
      touched: isSummaryTouched,
      hasBlockingIssues: summaryHasBlockingIssues,
      hasNonBlockingIssues: summaryHasNonBlockingIssues,
      completed: totalNumberOfSteps === numberOfStepsCompleted,
    });
  }

  /**
   * Clash between saved touched steps and already pre-calculated touched steps
   */
  const campaignSettings = parseJSONScalar<CampaignSettingsMap>(draftStepsCompleted) ?? DEFAULT_CAMPAIGN_SETTINGS;
  const touchedSteps = campaignSettings.touchedSteps;

  if (touchedSteps) {
    getObjectKeys(campaignSteps).forEach((step) => {
      const stepStatus = campaignSteps[step];
      const isStepTouched = touchedSteps[step];

      if (stepStatus && isStepTouched) {
        stepStatus.touched = isStepTouched || stepStatus.touched;
      }
    });
  }

  const contextValue: CampaignContextValue = {
    goal,
    isDraft,
    campaignId,
    isPerformance,
    campaignSteps,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    campaignPrettyId: campaignPrettyId!,
    hasBlockingIssues,
    externalCampaignId,
    shouldUseGoogleTagManager,
    settings: campaignSettings,
    draftIssues: campaignIssues,
    advertiserId: advertiser.id,
  };

  return <CampaignContext.Provider value={contextValue}>{children}</CampaignContext.Provider>;
};

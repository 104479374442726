import { useMutation, MutationHookOptions } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SetSessionTeamMutation, SetSessionTeamMutationVariables } from '__generated__/graphql';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const SET_SESSION_TEAM_MUTATION = gql(/* GraphQL */ `
  mutation setSessionTeam($input: SetSessionTeamInput!) {
    setSessionTeam(input: $input) {
      clientMutationId
    }
  }
`);

export function useSetSessionTeam(
  options?: MutationHookOptions<SetSessionTeamMutation, SetSessionTeamMutationVariables>,
) {
  const onError = useDefaultOnError();

  return useMutation(SET_SESSION_TEAM_MUTATION, {
    onError: (err) => onError(err),
    ...options,
  });
}

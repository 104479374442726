import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { config } from 'appConfig';
import { client } from 'client/client';
import { ErrorLoadingPage } from 'shared/ErrorLoadingPage';
import { LocalizedIntlProvider } from 'i18n/LocalizedIntlProvider';
import { LocaleProvider } from 'i18n/LocaleProvider';
import { ThemeProvider } from 'theme/ThemeProvider';
import { AppRouting } from './AppRouting';
import { SnackbarProvider } from './SnackbarProvider';
import { AuthProvider } from 'components/App/AuthProvider';

export const App: FunctionComponent = () => (
  <ApolloProvider client={client}>
    <LocaleProvider>
      <ThemeProvider>
        <IntercomProvider appId={config.intercomAppId}>
          <CssBaseline />
          <BrowserRouter>
            <AuthProvider>
              <SnackbarProvider>
                <ErrorBoundary fallback={<ErrorLoadingPage />}>
                  <LocalizedIntlProvider>
                    <AppRouting />
                  </LocalizedIntlProvider>
                </ErrorBoundary>
              </SnackbarProvider>
            </AuthProvider>
          </BrowserRouter>
        </IntercomProvider>
      </ThemeProvider>
    </LocaleProvider>
  </ApolloProvider>
);

import { gql } from '__generated__/gql';

import { Node } from 'typeDeclarations/graphql/base-types';

export interface CachedBillingProfileSessionTeamData {
  sessionTeam: Node & {
    billingProfile: Node;
  };
}

export const CACHED_BILLING_PROFILE_QUERY = gql(/* GraphQL */ `
  query cachedBillingProfileQuery {
    sessionTeam {
      id
      billingProfile {
        id
      }
    }
  }
`);

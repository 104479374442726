import { Suspense } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Header } from 'components/Header/Header';
import { CampaignProvider } from 'pages/Campaign/CampaignContext/CampaignProvider';
import { LoadingBlock } from 'shared/LoadingBlock';

type RouteState = { campaignUglyId?: string };

export const AppLayout = () => {
  const location = useLocation();
  const { campaignPrettyId } = useParams();

  // FIXME: This is a temporary solution until we find more time to properly work on this.
  //  It's because multiple pages need the <CampaignProvider />. However, we can't just wrap the whole app with it
  //  especially on routes that do not provide a campaignPrettyId or campaignUglyId since it'll throw errors.
  if ((location.state as RouteState)?.campaignUglyId || campaignPrettyId) {
    return (
      <>
        <Header />
        <CampaignProvider>
          <Suspense fallback={<LoadingBlock />}>
            <Outlet />
          </Suspense>
        </CampaignProvider>
      </>
    );
  }

  return (
    <>
      <Header />
      <Suspense fallback={<LoadingBlock />}>
        <Outlet />
      </Suspense>
    </>
  );
};

import { useContext } from 'react';
import { useMatch } from 'react-router-dom';

import { CampaignContext } from 'pages/Campaign/CampaignContext/CampaignContext';
import { CampaignStep, useGetOrderedCampaignsSteps } from 'pages/Campaign/useGetOrderedCampaignsSteps';
import { getCampaignStepURL } from 'pages/Campaign/utils/getCampaignStepURL';

interface RouteParams {
  step: CampaignStep;
  campaignPrettyId: string;
}

interface Step {
  url: string;
  name: CampaignStep;
}

export function useGetCurrentCampaignStep(): Step {
  const { campaignPrettyId, campaignId } = useContext(CampaignContext);

  const routeMatch = useMatch({
    path: '/campaign/:campaignPrettyId/:step/*',
  });

  const orderedSteps = useGetOrderedCampaignsSteps({ campaignUglyId: campaignId, campaignPrettyId });

  if (routeMatch) {
    const { step } = routeMatch.params as RouteParams;

    return {
      name: step,
      url: getCampaignStepURL(step, campaignPrettyId),
    };
  }

  const step = orderedSteps[0];

  return {
    name: step,
    url: getCampaignStepURL(step, campaignPrettyId),
  };
}

import { FunctionComponent, useState, PropsWithChildren } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import styled from '@emotion/styled';

import { SearchInput } from 'shared/SearchInput';

interface TeamsSearchFormProps {
  onSubmit: (value: string) => void;
}

const StyledForm = styled.form`
  padding: 0 2rem;
  margin: 0 auto;
`;

export const TeamsSearchForm: FunctionComponent<PropsWithChildren<TeamsSearchFormProps>> = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { formatMessage } = useExtendedIntl();

  const handleClear = () => {
    setInputValue('');
    onSubmit('');
  };

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(inputValue);
      }}
    >
      <SearchInput
        size="medium"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={formatMessage({ id: 'teams-submenu.search-input' })}
        onClear={handleClear}
      />
    </StyledForm>
  );
};

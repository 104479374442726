import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Divider, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

import { BillingProfileNode, TeamNode } from 'typeDeclarations/graphql/nodes';
import { ErrorMessage } from 'shared/ErrorMessage';
import { VatBillingProfileData } from './VatAccordion/fragments';
import { VatForm } from './VatAccordion/VatForm/VatForm';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

const WALLET_VAT_QUERY = gql(/* GraphQL */ `
  query walletVatQuery {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        topupAmountLimits {
          vatPercentage
        }
        ...vatBillingProfileFragment
      }
    }
  }
`);

interface WalletVatQueryData {
  sessionTeam: Pick<TeamNode, 'id' | 'modified'> & {
    billingProfile: VatBillingProfileData &
      Pick<BillingProfileNode, 'id' | 'modified'> & {
        topupAmountLimits: {
          vatPercentage: string | null;
        };
      };
  };
}

export const WalletVat: FunctionComponent = () => {
  const { formatMessage } = useExtendedIntl();

  const { data, loading, error } = useQuery<WalletVatQueryData>(WALLET_VAT_QUERY);

  if (loading) return null;
  if (error || !data) return <ErrorMessage />;

  const { billingProfile } = data.sessionTeam;
  const { vatPercentage } = billingProfile.topupAmountLimits;

  if (!vatPercentage || Number(vatPercentage) === 0) return null;

  return (
    <>
      <Stack rowGap={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          {formatMessage({ id: 'shared.vat-details' })}
        </Typography>
        <VatForm />
      </Stack>
      <Divider />
    </>
  );
};

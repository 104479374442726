import { gql } from '__generated__/gql';
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { useGetSessionData } from 'components/App/hooks/useGetSessionData';
import { client } from 'client/client';
import { DEFAULT_LOCALE } from 'i18n/constants';

const IS_LOGGED_IN_QUERY = gql(/* GraphQL */ `
  query checkAuthStatus {
    isLoggedIn
  }
`);

interface IsLoggedInQueryData {
  isLoggedIn: boolean;
}

export type Session = {
  team: {
    id: string;
    owner: {
      ddtId: string | null;
    };
  };
  user: {
    id: string;
    ddtId: string | null;
    isStaff: boolean;
    locale: string;
    theme: string | null;
  };
};

type AuthContextType = {
  session: Session | undefined;
  login: (data: Session, redirectTo?: string) => void;
  logout: () => void;
  fetchSessionData: () => Promise<Session | void>;
};

const AuthContext = createContext<AuthContextType>({
  session: undefined,
  login: () => {},
  logout: () => {},
  fetchSessionData: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [session, setSession, removeSession] = useLocalStorage<Session | undefined>('leadzai.session', undefined);

  const [getSessionData] = useGetSessionData();

  const login = useCallback(
    (data: Session, redirectTo?: string) => {
      setSession(data);
      navigate(redirectTo ?? '/campaigns');
    },
    [navigate, setSession],
  );

  const logout = useCallback(() => {
    removeSession();
    navigate('/auth/login', { replace: true });
  }, [navigate, removeSession]);

  const fetchSessionData = useCallback(async (): Promise<Session> => {
    const { data } = await getSessionData();

    if (!data) {
      throw new Error('Failed to fetch session data');
    }

    const { sessionUser, sessionTeam } = data;

    return {
      team: {
        id: sessionTeam.id,
        owner: sessionTeam.owner,
      },
      user: {
        id: sessionUser.id,
        ddtId: sessionUser.ddtId,
        isStaff: sessionUser.isStaff,
        locale: sessionUser.locale ?? DEFAULT_LOCALE,
        theme: sessionUser.theme,
      },
    };
  }, [getSessionData]);

  // Treat the back-end as the source of truth, for now.
  useEffect(() => {
    (async () => {
      const { data } = await client.query<IsLoggedInQueryData>({ query: IS_LOGGED_IN_QUERY });

      // If there's no data, abort.
      if (!data) {
        logout();
        return;
      }

      // If the user is logged in, but there's no session, fetch the session data.
      if (data.isLoggedIn && !session) {
        const sessionData = await fetchSessionData();

        login(sessionData);

        return;
      }

      if (!data.isLoggedIn && session) {
        logout();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({ session, login, logout, fetchSessionData }),
    [session, login, logout, fetchSessionData],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

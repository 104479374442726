import { useMutation, MutationHookOptions } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  CreateOrUpdateFrontendSettingMutation,
  CreateOrUpdateFrontendSettingMutationVariables,
} from '__generated__/graphql';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const CREATE_OR_UPDATE_FRONTEND_SETTING_MUTATION = gql(/* GraphQL */ `
  mutation createOrUpdateFrontendSetting($input: CreateOrUpdateFrontendSettingInput!) {
    createOrUpdateFrontendSetting(input: $input) {
      clientMutationId
      frontendSetting {
        id
        modified
        name
        value
      }
    }
  }
`);

export function useCreateOrUpdateFrontendSetting(
  options?: MutationHookOptions<CreateOrUpdateFrontendSettingMutation, CreateOrUpdateFrontendSettingMutationVariables>,
) {
  const onError = useDefaultOnError();

  return useMutation(CREATE_OR_UPDATE_FRONTEND_SETTING_MUTATION, {
    onError: (err) => onError(err),
    ...options,
  });
}

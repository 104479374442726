import { Close as CloseIcon } from '@mui/icons-material';
import { forwardRef, FunctionComponent, PropsWithChildren, ReactNode } from 'react';

import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { ProgressButton, ProgressButtonProps } from 'shared/ProgressButtons/ProgressButton';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Theme,
  useMediaQuery,
} from '@mui/material';

interface DefaultDialogTitleProps extends DialogTitleProps {
  closeButtonProps?: IconButtonProps;
}

export const DefaultDialogTitle: FunctionComponent<PropsWithChildren<DefaultDialogTitleProps>> = ({
  sx,
  children,
  closeButtonProps,
  ...remainingTitleProps
}) => {
  return (
    <DialogTitle
      sx={{
        pr: 2,
        py: 1.75,
        columnGap: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...remainingTitleProps}
    >
      {children}
      {closeButtonProps && (
        <IconButton
          {...closeButtonProps}
          size="small"
          sx={{
            alignSelf: 'flex-start',
            ...closeButtonProps.sx,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export const DefaultDialogDescription: FunctionComponent<PropsWithChildren<StackProps>> = ({
  sx,
  children,
  ...remainingContentProps
}) => {
  return (
    <Stack
      sx={{
        px: 3,
        pb: 2,
        pt: 0,
        ...sx,
      }}
      {...remainingContentProps}
    >
      {children}
    </Stack>
  );
};

export const DefaultDialogContent: FunctionComponent<PropsWithChildren<DialogContentProps>> = ({
  sx,
  children,
  ...remainingContentProps
}) => {
  return (
    <DialogContent
      dividers
      sx={{
        px: 3,
        py: 2,
        ...sx,
      }}
      {...remainingContentProps}
    >
      {children}
    </DialogContent>
  );
};

interface DefaultDialogActionsProps extends DialogActionsProps {
  children?: ReactNode;
  secondaryButtonProps?: ButtonProps;
  primaryButtonProps?: ProgressButtonProps;
}

export const DefaultDialogActions = forwardRef<HTMLDivElement, DefaultDialogActionsProps>(function DefaultDialogActions(
  { sx, children, primaryButtonProps, secondaryButtonProps, ...remainingActionsProps },
  ref,
) {
  const { formatMessage } = useExtendedIntl();

  return (
    <DialogActions
      ref={ref}
      sx={{
        px: 3,
        py: 1.5,
        columnGap: 1,
        justifyContent: 'flex-end',
        ...sx,
      }}
      {...remainingActionsProps}
    >
      {children}
      {(primaryButtonProps || secondaryButtonProps) && (
        <Stack columnGap={1} direction="row">
          {secondaryButtonProps && (
            <Button variant="text" {...secondaryButtonProps}>
              {secondaryButtonProps?.children || formatMessage({ id: 'shared.cancel' })}
            </Button>
          )}
          {primaryButtonProps && (
            <ProgressButton type="submit" variant="contained" {...primaryButtonProps}>
              {primaryButtonProps?.children || formatMessage({ id: 'shared.save' })}
            </ProgressButton>
          )}
        </Stack>
      )}
    </DialogActions>
  );
});

export interface DefaultDialogProps extends Omit<DialogProps, 'onClose' | 'title' | 'content'> {
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
  description?: ReactNode;
  renderTitle?: () => ReactNode;
  renderContent?: () => ReactNode;
  renderActions?: () => ReactNode;
  renderDescription?: () => ReactNode;
  dialogDescriptionProps?: StackProps;
  dialogContentProps?: Omit<DialogContentProps, 'children'>;
  dialogTitleProps?: Omit<DefaultDialogTitleProps, 'children'>;
  dialogActionsProps?: Omit<DefaultDialogActionsProps, 'ref' | 'children'>;
}

export const DefaultDialog: FunctionComponent<PropsWithChildren<DefaultDialogProps>> = ({
  sx,
  title,
  content,
  actions,
  onClose,
  description,
  renderTitle,
  renderContent,
  renderActions,
  TransitionProps,
  dialogTitleProps,
  renderDescription,
  dialogContentProps,
  dialogActionsProps,
  dialogDescriptionProps,
  ...remainingDialogProps
}) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'), { defaultMatches: true });

  // FIXME We are only doing this beacuse we need to use the DialogContent inside the form
  const defaultDialogDescription =
    renderDescription?.() ??
    (!description ? null : (
      <DefaultDialogDescription {...dialogDescriptionProps}>{description}</DefaultDialogDescription>
    ));

  const defaultDialogTitle =
    renderTitle?.() ??
    (!title && !dialogTitleProps ? null : (
      <DefaultDialogTitle
        {...dialogTitleProps}
        sx={{
          pb: defaultDialogDescription ? 0 : undefined,
          ...dialogTitleProps?.sx,
        }}
        closeButtonProps={{
          ...dialogTitleProps?.closeButtonProps,
          onClick: (e) => {
            onClose?.();
            dialogTitleProps?.closeButtonProps?.onClick?.(e);
          },
        }}
      >
        {title}
      </DefaultDialogTitle>
    ));

  const defaultDialogContent =
    renderContent?.() ??
    (!content ? null : <DefaultDialogContent {...dialogContentProps}>{content}</DefaultDialogContent>);

  const defaultDialogActions =
    renderActions?.() ??
    (!actions && !dialogActionsProps ? null : (
      <DefaultDialogActions {...dialogActionsProps}>{actions}</DefaultDialogActions>
    ));

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      closeAfterTransition
      fullScreen={isMobile}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
        ...TransitionProps,
      }}
      {...remainingDialogProps}
    >
      {/* DialogTitle */}
      {defaultDialogTitle}
      {/* DialogDescription */}
      {defaultDialogDescription}
      {/* DialogContent */}
      {defaultDialogContent}
      {/* DialogActions */}
      {defaultDialogActions}
    </Dialog>
  );
};

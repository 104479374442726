import { FunctionComponent, useContext } from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from 'i18n/constants';
import { DefaultScreen } from './DefaultScreen';
import { MainContainer } from './MainContainer';
import { LocaleContext } from 'i18n/LocaleContext';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { i18n } from 'i18n/translations/error-loading-page';
import { ReactComponent as ExpiredLinkSVG } from 'images/link-expired.svg';

export const Content: FunctionComponent = () => {
  const { formatMessage } = useExtendedIntl();

  return (
    <DefaultScreen
      SVG={ExpiredLinkSVG}
      title={formatMessage({ id: 'shared.something-went-wrong-message' }, { br: <br /> })}
      description={formatMessage({ id: 'shared.something-went-wrong-description' }, { br: <br /> })}
      CTAButtonProps={{
        onClick: () => window.location.reload(),
        children: formatMessage({ id: 'error-loading-page.button' }),
      }}
    />
  );
};

export const ErrorLoadingPage: FunctionComponent = () => {
  const { appLocale } = useContext(LocaleContext);

  return (
    <IntlProvider locale={appLocale} messages={i18n[appLocale]} defaultLocale={DEFAULT_LOCALE}>
      <MainContainer sx={{ pt: { xs: 2, sm: 4, md: 6 } }}>
        <Content />
      </MainContainer>
    </IntlProvider>
  );
};

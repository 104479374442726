import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'components/App/AuthProvider';

export const HomeLayout = () => {
  const { session } = useAuth();

  if (session) {
    return <Navigate to="/campaigns" replace />;
  }

  return <Outlet context={{ session }} />;
};

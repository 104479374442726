import { CampaignSettingsMap } from 'components/Campaign/Settings/types';
import { DraftIssueFragmentData } from 'graphql/fragments';
import { createContext, useContext } from 'react';

import { CampaignStep } from '../useGetOrderedCampaignsSteps';

export interface StepStatus {
  touched: boolean;
  completed: boolean;
  hasBlockingIssues: boolean;
  hasNonBlockingIssues: boolean;
}

export interface CampaignContextValue {
  isDraft: boolean;
  campaignId: string;
  goal: string | null;
  advertiserId: string;
  isPerformance: boolean;
  campaignPrettyId: string;
  hasBlockingIssues: boolean;
  settings: CampaignSettingsMap;
  externalCampaignId: string | null;
  draftIssues: DraftIssueFragmentData[];
  shouldUseGoogleTagManager: boolean | null;
  campaignSteps: PartialRecord<CampaignStep, StepStatus>;
}

// the value to instance the context. will be overwritten when calling `Context.Provider`
const defaultValue: CampaignContextValue = {
  settings: {},
  isDraft: false,
  draftIssues: [],
  campaignSteps: {},
  isPerformance: false,
  hasBlockingIssues: false,
  shouldUseGoogleTagManager: false,
  goal: 'default-campaign-context-goal',
  campaignId: 'default-campaign-context-id',
  externalCampaignId: 'default-campaign-context-id',
  advertiserId: 'default-campaign-advertiser-context-id',
  campaignPrettyId: 'default-campaign-context-pretty-id',
};

export const CampaignContext = createContext<CampaignContextValue>(defaultValue);

export const useCampaign = () => {
  const context = useContext(CampaignContext);

  if (context === undefined) {
    throw new Error('useCampaign must be used within a CampaignProvider');
  }

  return context;
};

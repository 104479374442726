import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Alert, Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { APIErrorCodesCatalog } from 'utils/APIErrorCodes/APIErrorCodesCatalog';
import { useUserTracking } from 'userTracking/useUserTracking';
import { FormValues } from 'hooks/useFormUtils';
import { LoadingBlock } from 'shared/LoadingBlock';
import { ErrorMessage } from 'shared/ErrorMessage';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { Node } from 'typeDeclarations/graphql/base-types';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { APIErrorCode } from 'utils/APIErrorCodes/APIErrorCode';
import { WalletManualTopUpForm } from './WalletManualTopUpForm/WalletManualTopUpForm';
import { WalletFormBillingProfileNode, WalletFormCurrencies } from './fragments';
import { isError } from 'typeDeclarations/typeGuards';

export interface WalletFormValues extends FormValues {
  currency: string;
  topupAmount: string;
}

const WALLET_FORM_QUERY = gql(/* GraphQL */ `
  query walletFormQuery {
    ...WalletFormCurrenciesFragment
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        ...WalletFormBillingProfileFragment
      }
    }
  }
`);

type WalletFormQueryData = {
  allCurrencies: WalletFormCurrencies;
  sessionTeam: Node & {
    modified: string;
    billingProfile: Node & { modified: string } & WalletFormBillingProfileNode;
  };
};

const TOPUP_BILLING_PROFILE = gql(/* GraphQL */ `
  mutation topupBillingProfile($input: TopupBillingProfileInput!) {
    topupBillingProfile(input: $input) {
      billingProfile {
        id
        balance
        modified
      }
    }
  }
`);

type TopupBillingProfileVariables = {
  input: {
    amount: string;
    paymentSourceId: string;
    paymentIntentId?: string;
  };
};

type WalletManualTopUpProps = {
  disabled: boolean;
};

const DEFAULT_TOP_UP_LIMITS = {
  minimumAmount: '0',
  maximumAmount: '5000',
};

export const WalletManualTopUp: FunctionComponent<WalletManualTopUpProps> = ({ disabled }) => {
  const elements = useElements();
  const stripe = useStripe();
  const onError = useDefaultOnError();

  const { enqueueSnackbar } = useSnackbar();
  const { captureEvent } = useUserTracking();
  const { formatMessage, formatNumber } = useExtendedIntl();

  const { data, loading, error } = useQuery<WalletFormQueryData>(WALLET_FORM_QUERY, { onError });

  const [topUpBalance, { loading: mutationLoading }] = useMutation<unknown, TopupBillingProfileVariables>(
    TOPUP_BILLING_PROFILE,
    {
      errorPolicy: 'all',
    },
  );

  if (loading) return <LoadingBlock />;
  if (error || !data) return <ErrorMessage />;

  const {
    allCurrencies,
    sessionTeam: { billingProfile },
  } = data;

  const { currency, primaryPaymentSource, topupAmountLimits } = billingProfile;

  const { minimumAmount, maximumAmount } = topupAmountLimits ?? DEFAULT_TOP_UP_LIMITS;

  const handle3dSecure = async (clientSecret: string, primaryPaymentSourceId: string, amount: string) => {
    if (!stripe || !elements) return;

    captureEvent({ eventName: 'cardAction3dSecureRequest' });

    const result = await stripe.handleCardAction(clientSecret);

    if (result.error) {
      captureException('Error during 3D Secure verification while processing wallet top-up.', {
        contexts: {
          error: {
            data: result.error,
          },
        },
      });

      enqueueSnackbar('3ds verification failed. Please try again later, or contact support.');

      return;
    }

    try {
      const { errors } = await topUpBalance({
        variables: {
          input: {
            amount,
            paymentSourceId: primaryPaymentSourceId,
            paymentIntentId: result.paymentIntent.id,
          },
        },
      });

      if (errors) {
        onError(errors);
        return;
      }

      enqueueSnackbar(formatMessage({ id: 'wallet-manual-top-up.success-message' }), { variant: 'success' });

      captureEvent({
        eventName: 'topUp',
        data: {
          currency,
          amount,
        },
      });
    } catch (e) {
      if (!isError(e)) return;

      onError(e);
    }
  };

  const handleFormikSubmit = async (values: WalletFormValues) => {
    if (!elements || !stripe || !primaryPaymentSource) return;

    const { error: submitError } = await elements.submit();

    if (submitError) {
      captureException('Invalid Payment Element state during wallet top-up.', {
        contexts: {
          error: {
            data: submitError,
          },
        },
      });

      enqueueSnackbar(
        'An unexpected error occurred while processing your payment. Please try again, or contact support.',
      );

      return;
    }

    try {
      const { errors } = await topUpBalance({
        variables: {
          input: {
            amount: values.topupAmount,
            paymentSourceId: primaryPaymentSource.id,
          },
        },
      });

      if (!errors || !errors.length) {
        enqueueSnackbar(formatMessage({ id: 'wallet-manual-top-up.success-message' }), { variant: 'success' });
        return;
      }

      const errorCodes = new APIErrorCodesCatalog(errors);
      const requiresActionErrorData = errorCodes.getErrorData(APIErrorCode.ChargeRequiresActions);

      if (!requiresActionErrorData) {
        onError(errors);
        return;
      }

      await handle3dSecure(
        requiresActionErrorData.payment_intent_client_secret,
        primaryPaymentSource.id,
        values.topupAmount,
      );
    } catch (e) {
      if (!isError(e)) return;

      onError(e);
    }
  };

  const validate = (values: WalletFormValues): object | undefined => {
    const topUpAmount = Number(values.topupAmount);

    const minAmount = Number(minimumAmount);
    const maxAmount = Number(maximumAmount);

    if (topUpAmount === 0) {
      return { topupAmount: formatMessage({ id: 'wallet-manual-top-up.provide-amount' }) };
    }

    if (topUpAmount < minAmount || topUpAmount > maxAmount) {
      const maximum = formatNumber(minAmount, {
        style: 'currency',
        currency: values.currency,
      });

      const minimum = formatNumber(maxAmount, {
        style: 'currency',
        currency: values.currency,
      });

      return { topupAmount: formatMessage({ id: 'topup-amount.error' }, { minimum, maximum }) };
    }
  };

  const isDisabled = disabled || !stripe || !elements;

  return (
    <Stack rowGap={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        {formatMessage({ id: 'wallet.top-up' })}
      </Typography>
      <Formik<WalletFormValues>
        initialValues={{
          currency,
          topupAmount: '',
        }}
        validate={validate}
        onSubmit={handleFormikSubmit}
      >
        {({ handleSubmit, errors }) => (
          <>
            {errors.generic && <Alert severity="error">{errors.generic}</Alert>}
            <WalletManualTopUpForm
              disabled={isDisabled}
              loading={mutationLoading}
              handleSubmit={handleSubmit}
              allCurrencies={allCurrencies}
              billingProfile={billingProfile}
            />
          </>
        )}
      </Formik>
    </Stack>
  );
};

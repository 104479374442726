import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { DefaultScreen } from 'shared/DefaultScreen';
import { MainContainer } from 'shared/MainContainer';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useIsMobileResolution } from 'hooks/useIsMobileResolution';
import { ReactComponent as NotFoundSVG } from 'images/link-expired.svg';

export const NotFound: FunctionComponent = () => {
  const isMobile = useIsMobileResolution();
  const { formatMessage } = useExtendedIntl();

  return (
    <MainContainer sx={{ pt: { xs: 2, sm: 4, md: 6 } }}>
      <DefaultScreen
        SVG={NotFoundSVG}
        title={formatMessage(
          { id: 'shared.404-page-message' },
          {
            br: <br />,
            p: (parts) => <p>{parts}</p>,
          },
        )}
        description={formatMessage(
          { id: 'shared.404-page-description' },
          {
            br: <br />,
            p: (parts) => <p>{parts}</p>,
          },
        )}
        CTAButtonProps={{
          component: Link,
          to: '/campaigns',
          children: formatMessage({ id: 'shared.back-to-homepage' }, { isMobile }),
        }}
      />
    </MainContainer>
  );
};

import { forwardRef, InputHTMLAttributes, useState, FocusEvent } from 'react';

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { NumericFormat, InputAttributes, NumericFormatProps } from 'react-number-format';

import { logError } from 'utils/logging';
import { UserNode } from 'typeDeclarations/graphql/nodes';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

const CUSTOM_NUMBER_FORMAT_SESSION_USER_QUERY = gql(/* GraphQL */ `
  query customNumberFormatSessionUserQuery {
    sessionUser {
      id
      modified
      locale
    }
  }
`);

interface CustomNumberFormatSessionUserQueryData {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'locale'>;
}

type InputHTMLAttributesSubset = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'type' | 'defaultValue'>;

type NumberFormatPropsSubset = Omit<NumericFormatProps, 'defaultValue'>;

// HACK: this interface is a way to make this component interop with MUI
interface CustomNumberFormatProps extends InputHTMLAttributesSubset, NumberFormatPropsSubset {
  inputRef?: (instance: InputAttributes | null) => void;
  // HACK: this is a merge of the types from InputHTMLAttributes and NumberFormatProps
  defaultValue?: string | number | readonly string[];
}

function isStringArray(value: string | number | readonly string[] | undefined): value is readonly string[] {
  // If `value` is an array, it can only be an array of strings, according to the type above.
  return Array.isArray(value);
}

// i need this so that CustomNumberFormatProps can quack like InputHTMLAttributes
function getNumberFormatDefaultValue(
  defaultValue: InputHTMLAttributes<HTMLInputElement>['defaultValue'],
): NumericFormatProps['defaultValue'] {
  if (isStringArray(defaultValue)) {
    logError(
      new Error(
        "Received an array as the CustomNumberFormat's defaultValue. It's type should be number | string | undefined",
      ),
    );
    return undefined;
  }

  return defaultValue;
}

export const CustomNumberFormat = forwardRef<unknown, CustomNumberFormatProps>(
  ({ defaultValue, onChange, name, value, onBlur, ...rest }, inputRef) => {
    const onError = useDefaultOnError();
    const [innerValue, setInnerValue] = useState<string>(value ? String(value) : '');

    const { data, error, loading } = useQuery<CustomNumberFormatSessionUserQueryData>(
      CUSTOM_NUMBER_FORMAT_SESSION_USER_QUERY,
      { onError },
    );

    const disabled = Boolean(loading || !data || error);

    const locale = data?.sessionUser.locale;

    let formattedDecimalSeparator = ',';
    let formattedThousandSeparator = '.';

    if (locale === 'en_US') {
      formattedDecimalSeparator = '.';
      formattedThousandSeparator = ',';
    }

    return (
      <NumericFormat
        name={name}
        // FIXME this should not be hardcoded. This was also causing an error from BE if > 2
        decimalScale={2}
        disabled={disabled}
        valueIsNumericString
        allowNegative={false}
        getInputRef={inputRef}
        allowLeadingZeros={false}
        value={value ? String(value) : ''}
        decimalSeparator={formattedDecimalSeparator}
        thousandSeparator={formattedThousandSeparator}
        defaultValue={getNumberFormatDefaultValue(defaultValue)}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          if (onBlur) {
            e.target.value = innerValue;
            e.currentTarget.value = innerValue;
            onBlur(e);
          }
        }}
        onValueChange={(values) => {
          setInnerValue(values.value);
          if (onChange) {
            // i had to do this sad hack.
            // NumberFormat's onChange event.target.value is a formatted string,
            // not the actual value.
            // i also cannot save the input's onChange event in a ref and use it here
            // because onValueChange is called too soon.
            // the event might not exist yet or it might be not be the most recent event.
            // so i must create my own event object
            // and cast this event object as an any because it doesnt quack like a ChangeEvent.
            // make it's value be the unformatted value so that the parent can use it.
            // also formik needs an event.target.name to handle form state.
            // that's why i added a name key.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const event: any = {
              target: {
                name,
                value: values.value,
              },
            };
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            onChange(event);
          }
        }}
        {...rest}
      />
    );
  },
);

import { isNull, isObject, isUndefined } from 'typeDeclarations/typeGuards';

type OnlyDefinedKeys<T> = Pick<
  T,
  {
    [K in keyof T]: undefined extends T[K] ? never : K;
  }[keyof T]
>;

type OnlyUndefinedKeys<T> = Pick<
  T,
  {
    [K in keyof T]: undefined extends T[K] ? K : never;
  }[keyof T]
>;

type SanitizedMutationInput<T> =
  T extends Array<infer U>
    ? Array<SanitizedMutationInput<U>>
    : T extends Record<string, unknown>
      ? {
          [DEFINED_KEY in keyof OnlyDefinedKeys<T>]: SanitizedMutationInput<T[DEFINED_KEY]>;
        } & Partial<{
          [UNDEFINED_KEY in keyof OnlyUndefinedKeys<T>]?: SanitizedMutationInput<T[UNDEFINED_KEY]>;
        }>
      : T;

export function sanitizeInput<T>(input: T): SanitizedMutationInput<T> {
  if (Array.isArray(input)) {
    return input.map(sanitizeInput) as SanitizedMutationInput<T>;
  }

  // 'typeof null' is 'object'
  if (!isNull(input) && isObject(input)) {
    const sanitizedObject: Record<string, unknown> = {};

    Object.keys(input).forEach((key) => {
      const value = input[key];
      // should remove undefined keys from the 'inputObject'
      if (!isUndefined(value)) {
        sanitizedObject[key] = sanitizeInput(value);
      }
    });

    return sanitizedObject as SanitizedMutationInput<T>;
  }

  // from this point on, we are sure that this is a scalar
  const scalarInput = input as unknown;
  // empty string or undefined values should be sent as null
  if (scalarInput === '' || isUndefined(scalarInput)) {
    return null as unknown as SanitizedMutationInput<T>;
  }

  return input as SanitizedMutationInput<T>;
}

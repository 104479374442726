import styled from '@emotion/styled';
import {
  AccountBalance as AccountBalanceIcon,
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  Construction as ConstructionIcon,
  CreditCard as CreditCardIcon,
  Redeem as RedeemIcon,
} from '@mui/icons-material';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { config } from 'appConfig';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { TeamsMenu } from '../TeamsMenu/TeamsMenu';
import { APIPermission } from 'typeDeclarations/enums';
import { FeatureFlagsList } from 'featureFlags/FeatureFlagsList';
import { DefaultDialog } from 'shared/DefaultDialog/DefaultDialog';
import { PermissionsViewDecider } from 'shared/PermissionsViewDecider';
import { AccountArrowIconRight, BullhornIcon } from 'components/Icons/index';
import { NavigationMenuHeader } from './NavigationMenuHeader/NavigationMenuHeader';

interface NavigationMenuProps {
  onLinkClick: () => void;
  onMoreTeamsClick: () => void;
  onTeamClick: (teamId: string) => void;
  onAddMoneyClick: () => void;
}

const StyledListNav = styled(List)`
  width: 18rem;
  min-height: 30rem;
` as typeof List;

const StyledDivider = styled(Divider)`
  margin: 0.5rem 1rem;
`;

export const NavigationMenu = forwardRef<HTMLElement, NavigationMenuProps>(
  ({ onLinkClick, onMoreTeamsClick, onAddMoneyClick, onTeamClick }, ref) => {
    const theme = useTheme();
    const { formatMessage } = useExtendedIntl();
    const [isFeatureFlagsDialogOpen, setIsFeatureFlagsDialogOpen] = useState(false);

    return (
      <>
        <StyledListNav component="nav" ref={ref}>
          {/* FIXME change name to reflect re-rendering and not closing */}
          <NavigationMenuHeader onLinkClick={onLinkClick} onAddMoneyClick={onAddMoneyClick} />
          <ListItemButton to="/businesses" component={Link} onClick={onLinkClick}>
            <ListItemIcon>
              <BusinessIcon htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'shared.advertiser' }, { plural: true })}</ListItemText>
          </ListItemButton>
          <ListItemButton to="/campaigns" component={Link} onClick={onLinkClick}>
            <ListItemIcon>
              <BullhornIcon htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'shared.campaign' }, { plural: true })}</ListItemText>
          </ListItemButton>
          <TeamsMenu onMoreTeamsClick={onMoreTeamsClick} onTeamClick={onTeamClick} />
          <StyledDivider light />
          <PermissionsViewDecider requiredPermissions={[APIPermission.EstimatedCostPerGoalWithUrl]}>
            <ListItemButton component={Link} to="/budget-estimator" onClick={onLinkClick}>
              <ListItemIcon>
                <AccountBalanceIcon htmlColor={theme.palette.text.primary} />
              </ListItemIcon>
              <ListItemText>{formatMessage({ id: 'navigation-menu.budget-estimator' })}</ListItemText>
            </ListItemButton>
          </PermissionsViewDecider>
          <ListItemButton onClick={() => setIsFeatureFlagsDialogOpen(true)}>
            <ListItemIcon>
              <ConstructionIcon htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'feature-flags.beta-features' })}</ListItemText>
          </ListItemButton>
          <StyledDivider light />
          <ListItemButton component={Link} onClick={onLinkClick} to="/account-settings/invite-a-friend">
            <ListItemIcon>
              <RedeemIcon color="secondary" />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'shared.invite-friends' })}</ListItemText>
          </ListItemButton>
          <ListItemButton component={Link} to="/team/billing" onClick={onLinkClick}>
            <ListItemIcon>
              <CreditCardIcon htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'navigation-menu.team-management' })}</ListItemText>
          </ListItemButton>
          <ListItemButton component={Link} to="/account-settings" onClick={onLinkClick}>
            <ListItemIcon>
              <AccountCircleIcon htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'navigation-menu.account-settings' })}</ListItemText>
          </ListItemButton>
          <StyledDivider light />
          <ListItemButton component={Link} to={config.routes.logout}>
            <ListItemIcon>
              <AccountArrowIconRight htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'navigation-menu.log-out' })}</ListItemText>
          </ListItemButton>
        </StyledListNav>
        <DefaultDialog
          content={<FeatureFlagsList />}
          open={isFeatureFlagsDialogOpen}
          onClose={() => setIsFeatureFlagsDialogOpen(false)}
          title={formatMessage({ id: 'feature-flags.beta-features' })}
          description={formatMessage({ id: 'feature-flags.description' })}
        />
      </>
    );
  },
);

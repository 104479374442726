import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';

import { ThemeName } from 'appConfig';
import { userThemeVar } from 'client/cache';
import { isUndefined } from 'typeDeclarations/typeGuards';

interface PartnersThemeDeciderProps extends PartialRecord<ThemeName, ReactElement | null> {
  fallback: ReactElement | null;
}

/**
 * This component is used to decide which element to return based on the current theme name.
 * If the theme is not found in the themes object, the fallback element is returned.
 *
 * @param fallback - The fallback element to return if the theme is not found in the themes object
 * @param themes - The themes object where the key is the theme name and the value is the element to return
 */
export const PartnersThemeDecider: FunctionComponent<PropsWithChildren<PartnersThemeDeciderProps>> = ({
  fallback,
  children,
  ...themes
}) => {
  const currentTheme = userThemeVar();

  const element = themes[currentTheme];

  return isUndefined(element) ? fallback : element;
};

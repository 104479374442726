import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

import { config } from 'appConfig';

const BREADCRUMB_MESSAGE_BLACKLIST: string[] = [
  'You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS.',
];

// Initializes Sentry service with default config if error reporting is enabled on the application
export function configureSentry(): void {
  if (!config.enableErrorReporting) return;

  init({
    dsn: config.sentryDSN,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    autoSessionTracking: true,
    release: config.release,
    environment: config.buildEnvironment,
    maxValueLength: 250000,
    tracesSampleRate: 0.1,
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    ignoreErrors: [
      'Error: GraphQL error: You must login to access this endpoint',
      'Error: GraphQL error: CSRF verification failed. Request aborted. CSRF cookie not set.',
      // (Apollo) This happens when the user navigates away from the page before the request is complete.
      // It fills our sentry logs with noise, so we ignore it.
      'AbortError: signal is aborted without reason',
      'Network error: Response not successful: Received status code 401',
      'Network error: Response not successful: Received status code 403',
      'Network error: NetworkError when attempting to fetch resource.',
      'Network error: Failed to fetch',
      'Network error: cancelled',
      'cancelled',
      'Load failed',
      'Failed to fetch',
      'Fetch is aborted',
      'The operation was aborted.',
      'The network connection was lost.',
      'Importing a module script failed.',
      'AbortError: The user aborted a request.',
      'error loading dynamically imported module',
      'Failed to fetch dynamically imported module',
      "Can't find variable: __AutoFillPopupClose__",
      'NetworkError when attempting to fetch resource.',
      'Network error: NetworkError when attempting to fetch resource.',
      // We get way too many errors about this, and there's nothing we can do about it for now.
      'Invalid business_name: None',
    ],
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.message && BREADCRUMB_MESSAGE_BLACKLIST.includes(breadcrumb.message)) {
        return null;
      }

      return breadcrumb;
    },
  });
}

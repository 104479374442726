import { FunctionComponent, PropsWithChildren } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const VisaCardBrand: FunctionComponent<PropsWithChildren<SvgIconProps>> = (props) => (
  <SvgIcon width="51" height="17" viewBox="0 0 51 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.573 16.5377H17.6523L20.1029 0.501953H24.0248L21.573 16.5377Z"
      fill="#0066B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2264 0.501953L10.3218 11.5315L9.85925 9.15624V9.15692L8.4815 1.94581C8.4815 1.94581 8.31572 0.501953 6.53889 0.501953H0.0833511L0.0078125 0.773826C0.0078125 0.773826 1.98184 1.19152 4.29279 2.60607L7.85112 16.5377H12.1187L18.6351 0.501953H14.2264Z"
      fill="#0066B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.6617 10.8656L43.8327 4.86323L45.0551 10.8656H41.6617ZM46.211 16.5377H50.0065L46.6968 0.501953H43.3742C41.8394 0.501953 41.4656 1.69642 41.4656 1.69642L35.3008 16.5377H39.609L40.4712 14.1562H45.726L46.211 16.5377Z"
      fill="#0066B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.6975 4.12725L36.2821 0.696474C36.2821 0.696474 34.4766 0 32.5961 0C30.5611 0 25.7312 0.902158 25.7312 5.28872C25.7312 9.41666 31.4007 9.46757 31.4007 11.6344C31.4007 13.8019 26.3152 13.4149 24.6368 12.0478L24.0273 15.6333C24.0273 15.6333 25.857 16.5369 28.6546 16.5369C31.4509 16.5369 35.672 15.0665 35.672 11.0676C35.672 6.91383 29.9517 6.52622 29.9517 4.72055C29.9517 2.9142 33.9434 3.14635 35.6975 4.12725Z"
      fill="#0066B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.81165 9.52205L8.44056 2.00672C8.44056 2.00672 8.27558 0.501953 6.50733 0.501953H0.0829861L0.0078125 0.785296C0.0078125 0.785296 3.09658 1.46773 6.05762 4.02634C8.88961 6.47346 9.81165 9.52205 9.81165 9.52205Z"
      fill="#F9A533"
    />
  </SvgIcon>
);

export default VisaCardBrand;

import { ApolloError, useApolloClient } from '@apollo/client';
import { gql } from '__generated__/gql';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TeamNode, UserNode } from 'typeDeclarations/graphql/nodes';

import { useDefaultOnError } from './useDefaultOnError';

/**
 * Fragment and type created for the optimization purpose during the login process
 */

export const SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment ShouldShowFirstBusinessPromptQueryFragment on Query {
    sessionUser {
      id
      modified
      ddtId
    }
    sessionTeam {
      id
      modified
      shouldShowFirstBusinessPrompt
    }
  }
`);

export interface ShouldShowFirstBusinessPromptQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'ddtId'>;
  sessionTeam: Pick<TeamNode, 'id' | 'modified' | 'shouldShowFirstBusinessPrompt'>;
}

const SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY = gql(/* GraphQL */ `
  query shouldShowFirstBusinessPromptQuery {
    ...ShouldShowFirstBusinessPromptQueryFragment
  }
`);

type ShouldShowFirstBusinessPromptQueryData = ShouldShowFirstBusinessPromptQueryFragmentData;

interface UseShouldShowFirstBusinessPromptReturnValue {
  loading: boolean;
  error?: Error;
}

export function useShouldShowFirstBusinessPrompt(): UseShouldShowFirstBusinessPromptReturnValue {
  const navigate = useNavigate();
  const client = useApolloClient();
  const onError = useDefaultOnError();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    client
      .query<ShouldShowFirstBusinessPromptQueryData>({
        query: SHOULD_SHOW_FIRST_BUSINESS_PROMPT_QUERY,
      })
      .then(({ data, errors }) => {
        if (errors) throw new ApolloError({ graphQLErrors: errors });

        const { sessionUser, sessionTeam } = data;

        if (!sessionUser.ddtId && sessionTeam.shouldShowFirstBusinessPrompt) {
          navigate('/first-business', { replace: true });
        }
      })
      .catch((err: Error) => {
        onError(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [client, navigate, onError]);

  return { loading, error };
}

import { FunctionComponent, PropsWithChildren } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { FiberManualRecordRounded as FiberManualRecordRoundedIcon } from '@mui/icons-material';
import { useSetSessionTeam } from 'hooks/mutations/useSetSessionTeam';
import { teamsColorPalette } from '../utils';
import { CreateTeamForm, CreateTeamMutationData } from './CreateTeamForm';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { DefaultFormDialog } from 'shared/DefaultDialog/DefaultFormDialog';
import { Stack } from '@mui/material';

interface CreateTeamDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CreateTeamDialog: FunctionComponent<PropsWithChildren<CreateTeamDialogProps>> = ({ open, onClose }) => {
  const { formatMessage } = useExtendedIntl();
  const onError = useDefaultOnError();

  const teamColorIndex = Math.floor(Math.random() * teamsColorPalette.length);
  const teamColor = teamsColorPalette[teamColorIndex];

  const [setSessionTeam] = useSetSessionTeam({
    onCompleted: () => {
      window.location.href = '/';
    },
  });

  const onCompleted = (data: CreateTeamMutationData) => {
    setSessionTeam({
      variables: {
        input: {
          id: data.createTeam.team.id,
        },
      },
    });

    onClose();
  };

  return (
    <DefaultFormDialog
      open={open}
      onClose={onClose}
      title={
        <Stack direction="row">
          <FiberManualRecordRoundedIcon htmlColor={teamColor} sx={{ transform: 'scale(0.5)' }} />
          {formatMessage({ id: 'create-team-button.title' })}
        </Stack>
      }
      renderContent={({ handleUnsavedChanges }) => (
        <CreateTeamForm onError={onError} onCompleted={onCompleted} onCancelled={handleUnsavedChanges} />
      )}
    />
  );
};

import { FunctionComponent, useState, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { FormHelperText, Stack } from '@mui/material';

const StyledCardElement = styled(CardElement)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  border: 1px solid rgba(0, 0, 0, 0.23);
  outline: 0;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
  height: 3.5rem;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-bottom: 0.5;

  .__PrivateStripeElement {
    width: 100%;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &.StripeElement--invalid {
    border-color: ${({ theme }) => theme.palette.error.main};
  }

  &.StripeElement--focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface StripeCardInputProps {
  disabled?: boolean;
  onChange?: (event: StripeCardElementChangeEvent) => void;
  // FIXME This any can be replaced by an unknown but Stripe types this callback with any.
  // What should we do?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReady?: (element: StripeCardElement) => any;
}

export const StripeCardInput: FunctionComponent<PropsWithChildren<StripeCardInputProps>> = ({
  disabled = false,
  onChange,
  onReady,
}) => {
  const { formatMessage } = useExtendedIntl();
  const [error, setError] = useState<string | undefined>(undefined);

  const onChangeHandler = (event: StripeCardElementChangeEvent) => {
    setError(event.error?.code);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Stack sx={{ width: '100%', position: 'relative' }}>
      <StyledCardElement
        options={{
          style: {
            base: {
              fontFamily: 'inherit',
            },
            invalid: {
              color: '#9e2146',
            },
          },
          hidePostalCode: true,
          disabled,
        }}
        onReady={onReady}
        onChange={onChangeHandler}
      />
      <Stack
        sx={{
          position: 'absolute',
          bottom: '-21px',
        }}
      >
        {error && <FormHelperText error>{formatMessage({ id: `stripe-card.${error}` })}</FormHelperText>}
      </Stack>
    </Stack>
  );
};

export function captureEvent(eventName: string, data?: Record<string, unknown>): void {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: 'appEvent',
    eventName,
    ...data,
  });
}

import { FunctionComponent, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import { useExtendedIntl } from 'hooks/useExtendedIntl';

import { CampaignStep } from '../useGetOrderedCampaignsSteps';
import { APP_HEADER_ID } from 'components/Header/Header';
import { CampaignContext } from '../CampaignContext/CampaignContext';
import { CampaignStepIcon } from './CampaignStepIcon';
import { LaunchCampaignButton } from './LaunchCampaignButton/LaunchCampaignButton';
import { getObjectKeys } from 'utils/getObjectKeys';
import { useIsMobileResolution } from 'hooks/useIsMobileResolution';
import { Link as RouterLink } from 'react-router-dom';
import { getCampaignStepURL } from '../utils/getCampaignStepURL';
import { useGetCurrentCampaignStep } from 'components/Campaign/useGetCurrentCampaignStep';
import { getAggregateStepStatus, StepStatusIconProps } from '../StepStatusIcon';
import {
  Button,
  Container,
  MobileStepper,
  Skeleton,
  Step,
  StepButton,
  StepLabel,
  StepLabelProps,
  Stepper,
} from '@mui/material';
import { Section } from 'shared/Sections/Section';

export const CAMPAIGN_STEPS_BAR_ID = 'campaign-steps-bar';

const appHeaderHTMLElement = document.getElementById(APP_HEADER_ID);

// FIXME Disgusting. All this logic should be coupled with the Icon one which is very similar
const StyledStepLabel = styled(
  ({ warning, touched, ...rest }: StepLabelProps & { warning?: boolean; touched?: boolean }) => <StepLabel {...rest} />,
)`
  .MuiStepLabel-label {
    ${({ warning, touched, theme: { palette }, error }) => {
      if (!touched) return `color: ${palette.text.secondary};`;
      if (error) return `color: ${palette.error.main};`;
      if (warning) return `color: ${palette.warning.main};`;
      return '';
    }}
    &.Mui-active {
      color: ${({ theme }) => theme.palette.text.primary};
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }
`;

const StepsBarWrapper: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  return (
    <Section
      sx={{
        px: 0,
        py: 0,
        zIndex: 2,
        position: 'sticky',
        top: appHeaderHTMLElement?.offsetHeight ?? 0,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          height: { xs: 52, md: 77 },
          justifyContent: 'space-between',
        }}
      >
        {children}
      </Container>
    </Section>
  );
};

export const StepsBarSkeleton = () => {
  return (
    <StepsBarWrapper>
      <Skeleton width="100%" height={12} />
    </StepsBarWrapper>
  );
};

export const StepsBar: FunctionComponent = () => {
  const isMobile = useIsMobileResolution();
  const { formatMessage } = useExtendedIntl();
  const { name: currentStep } = useGetCurrentCampaignStep();

  const { isDraft, campaignSteps, campaignPrettyId } = useContext(CampaignContext);

  const orderedSteps = getObjectKeys(campaignSteps);
  const currentStepIndex = orderedSteps.findIndex((step) => step === currentStep);

  function getPreviousRoute() {
    const prevStep = orderedSteps[currentStepIndex - 1];

    return getCampaignStepURL(prevStep, campaignPrettyId);
  }

  function getNextRoute() {
    const nextStep = orderedSteps[currentStepIndex + 1];

    return getCampaignStepURL(nextStep, campaignPrettyId);
  }

  const isLastStep = currentStepIndex === orderedSteps.length - 1;

  let nextButton;

  if (!isLastStep) {
    nextButton = (
      <Button component={RouterLink} to={getNextRoute()} variant="outlined" data-testid="steps-bar.next">
        {formatMessage({ id: 'shared.next' })}
      </Button>
    );
  } else if (isDraft) {
    nextButton = <LaunchCampaignButton />;
  } else {
    nextButton = (
      <Button component={RouterLink} to="/campaigns" variant="outlined">
        {formatMessage({ id: 'shared.back-to-homepage' }, { isMobile: true })}
      </Button>
    );
  }

  const backButton = (
    <Button
      variant="outlined"
      to={getPreviousRoute()}
      component={RouterLink}
      disabled={currentStepIndex < 1}
      data-testid="steps-bar.back"
    >
      {formatMessage({ id: 'shared.back' })}
    </Button>
  );

  const renderStep = (step: CampaignStep) => {
    let statusIconProps: StepStatusIconProps | undefined;

    const stepStatus = campaignSteps[step];
    if (stepStatus) {
      const aggregateStatus = getAggregateStepStatus(stepStatus);

      if (aggregateStatus) {
        statusIconProps = { aggregateStatus };
      }
    }

    return (
      <Step key={step} completed={stepStatus?.completed}>
        <StepButton
          disableRipple
          component={RouterLink}
          to={getCampaignStepURL(step, campaignPrettyId)}
          icon={<></>} // FIXME Don't ask me why but this makes the whole thing work
        >
          <StyledStepLabel
            touched={stepStatus?.touched}
            error={stepStatus?.hasBlockingIssues}
            warning={stepStatus?.hasNonBlockingIssues}
            data-testid={`steps-bar.${step}`}
            StepIconComponent={(props) => <CampaignStepIcon {...props} stepStatusIconProps={statusIconProps} />}
          >
            {formatMessage({ id: `campaign-step.${step}` })}
          </StyledStepLabel>
        </StepButton>
      </Step>
    );
  };

  return (
    <StepsBarWrapper>
      {isMobile ? (
        <MobileStepper
          variant="dots"
          position="static"
          nextButton={nextButton}
          backButton={backButton}
          steps={orderedSteps.length}
          activeStep={currentStepIndex}
          sx={{
            py: 1,
            px: 0,
            flex: 1,
            backgroundColor: 'background.paper',
          }}
        />
      ) : (
        <>
          {backButton}
          <Stepper nonLinear sx={{ p: 3, flex: 1 }} activeStep={currentStepIndex}>
            {orderedSteps.map(renderStep)}
          </Stepper>
          {nextButton}
        </>
      )}
    </StepsBarWrapper>
  );
};

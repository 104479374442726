import { isCurrentAppTheme } from 'appConfig';
import { useShouldShowACS } from 'components/Campaign/useShouldShowACS';
import { exists } from 'typeDeclarations/typeGuards';

export type CampaignStep = 'ads' | 'budget' | 'summary' | 'keywords' | 'audiences' | 'locations' | 'ads-and-keywords';

interface UseGetOrderedCampaignsStepsArgs {
  campaignUglyId?: string;
  campaignPrettyId?: string;
}

/**
 * This is an ordered list of the campaign's steps. We base our routing flow on this order.
 */
export function useGetOrderedCampaignsSteps(args: UseGetOrderedCampaignsStepsArgs): CampaignStep[] {
  const shouldShowACS = useShouldShowACS(args);

  if (!exists(shouldShowACS)) return [];

  const ORDERED_CAMPAIGN_STEPS: CampaignStep[] = shouldShowACS
    ? ['locations', 'ads-and-keywords', 'audiences', 'budget', 'summary']
    : ['locations', 'ads', 'audiences', 'keywords', 'budget', 'summary'];

  const ORDERED_CAMPAIGN_STEPS_CA: CampaignStep[] = shouldShowACS
    ? ['locations', 'audiences', 'ads-and-keywords', 'budget', 'summary']
    : ['locations', 'audiences', 'keywords', 'ads', 'budget', 'summary'];

  const isCanadianTheme = isCurrentAppTheme('yp-ca');

  return isCanadianTheme ? ORDERED_CAMPAIGN_STEPS_CA : ORDERED_CAMPAIGN_STEPS;
}

import { useReactiveVar } from '@apollo/client';
import { ThemeOptions } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { FunctionComponent, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { darkThemeVar, userThemeVar } from 'client/cache';
import { createTheme } from './theme';
import { logError } from 'utils/logging';
import { SupportedLocale } from 'i18n/constants';
import { LocaleContext } from 'i18n/LocaleContext';
import { config, themeConfig } from 'appConfig';

function toMuiLocaleName(locale: SupportedLocale): string {
  switch (locale) {
    case 'fr-BE':
      return 'frFR';
    case 'pt-PT':
      return 'ptBR';
    default:
      return locale.replace('-', '');
  }
}

/**
 * Get the MUI locale from data-grid, and date-picker packages based on the app's current locale.
 * Defaults to en-US.
 *
 * @param {SupportedLocale} locale The current app's locale
 * @returns {Promise<object[]>} The imported locales
 */
async function getMuiLocale(locale: SupportedLocale): Promise<object[]> {
  // It is unnecessary to load the en-US locale, as it is currently the default MUI setting already.
  if (locale === 'en-US') {
    return [];
  }

  const muiLocaleName = toMuiLocaleName(locale);

  type LocaleImportType = { [key: typeof muiLocaleName]: ThemeOptions };

  const localeImportPromises = [
    import(`../../node_modules/@mui/x-data-grid/locales/${muiLocaleName}.js`) as Promise<LocaleImportType>,
    import(`../../node_modules/@mui/x-date-pickers/locales/${muiLocaleName}.js`) as Promise<LocaleImportType>,
  ];

  return (await Promise.all(localeImportPromises)).map((importedLocale) => importedLocale[muiLocaleName]);
}

export const ThemeProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const darkTheme = useReactiveVar(darkThemeVar);
  const userTheme = useReactiveVar(userThemeVar);
  const { appLocale } = useContext(LocaleContext);
  const [additionalThemeOptions, setAdditionalThemeOptions] = useState<object[]>();

  const theme = useMemo(
    () => createTheme(darkTheme ? 'dark' : 'light', userTheme, additionalThemeOptions),
    [darkTheme, userTheme, additionalThemeOptions],
  );

  useEffect(() => {
    getMuiLocale(appLocale).then(setAdditionalThemeOptions).catch(logError);
  }, [appLocale]);

  // TODO: Can probably be improved.
  //  I only moved it to a useEffect for now, and returned early if the whitelabel_url is not set.
  useEffect(() => {
    document.title = themeConfig[userTheme].title;

    if (!config.whitelabel_url) return;

    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');

    (link as HTMLLinkElement).rel = 'icon';
    (link as HTMLLinkElement).href = `${config.whitelabel_url}${userTheme}/public/favicon.ico`;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [userTheme]);

  /**
   * Note: This only prevents flickering in the case that the browser language
   * matches the user's settings language. We can not suspend rendering until the
   * settings language is loaded because it's done in a child of this component.
   */
  if (!additionalThemeOptions) return null;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

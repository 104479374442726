import { FunctionComponent, ReactNode, PropsWithChildren } from 'react';

import { objectToClassName } from 'utils/objectToClassName';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardProps,
  CardHeaderProps,
  CardContentProps,
  CardActionsProps,
  SxProps,
  Theme,
} from '@mui/material';

const styles: SxProps<Theme> = {
  width: '100%',
  borderRadius: 1,
  boxShadow: '2px 8px 16px rgba(24, 50, 115, 0.06)',
  '&.selected': {
    borderColor: (t) => t.palette.primary.light,
  },
  '.MuiCardHeader-root': {
    justifyContent: 'space-between',
  },
  '.MuiCardHeader-content': {
    maxWidth: '90%',
  },
  '.MuiCardContent-root:last-child': {
    paddingBottom: '1rem',
  },
  '.MuiCardActions-root': {
    justifyContent: 'flex-end',
    backgroundColor: (t) => t.palette.grey[50],
  },
};

export interface ExtensionCardProps extends Omit<CardProps, 'title' | 'content'> {
  className?: string;
  disabled?: boolean;
  highlight?: boolean;
  content?: ReactNode;
  actions?: ReactNode;
  headerProps?: CardHeaderProps;
  actionsProps?: CardActionsProps;
  contentProps?: CardContentProps;
}

export const ExtensionCard: FunctionComponent<PropsWithChildren<ExtensionCardProps>> = ({
  actions,
  content,
  headerProps,
  actionsProps,
  contentProps,
  className = '',
  disabled = false,
  highlight = false,
  ...remainingCardProps
}) => (
  <Card
    variant="outlined"
    className={`${className} ${objectToClassName({ selected: highlight, disabled })}`}
    {...remainingCardProps}
    sx={{
      ...styles,
      ...remainingCardProps.sx,
    }}
  >
    {headerProps && <CardHeader {...headerProps} />}
    {content && <CardContent {...contentProps}>{content}</CardContent>}
    {actions && <CardActions {...actionsProps}>{actions}</CardActions>}
  </Card>
);

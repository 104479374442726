import { Field, FieldHookConfig, useField } from 'formik';
import { MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';

import { CurrencyPrettyId } from 'typeDeclarations/graphql/nodes';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { ChangeEvent } from 'react';

export function getCurrencyFlagSvg(currency: CurrencyPrettyId): string {
  return `https://flagcdn.com/${currency.slice(0, 2).toLowerCase()}.svg`;
}

type BaseCurrencySelectFieldProps = TextFieldProps & {
  currencies: CurrencyPrettyId[];
  extended?: boolean;
};

type CurrencySelectFieldProps = BaseCurrencySelectFieldProps & FieldHookConfig<CurrencyPrettyId>;

export const CurrencySelectField = ({ currencies, extended, sx, onChange, ...props }: CurrencySelectFieldProps) => {
  const { formatCurrency } = useExtendedIntl();
  const [{ onChange: defaultOnChange, ...field }, { error }] = useField(props);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    defaultOnChange(event);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Field
      as={TextField}
      select
      fullWidth
      error={!!error}
      sx={{
        '.MuiSelect-select': { display: 'flex', alignItems: 'center', gap: 1 },
        ...sx,
      }}
      {...props}
      {...field}
      onChange={handleChange}
    >
      {currencies.map((id) => (
        <MenuItem key={id} value={id} sx={{ gap: 1 }}>
          {extended && <img width="23" height="15" loading="lazy" alt={`flag-${id}`} src={getCurrencyFlagSvg(id)} />}
          <Typography variant="body2" component="span">
            {id}
          </Typography>
          {extended && (
            <>
              <Typography variant="body2" color="textSecondary" component="span">
                &#8211;
              </Typography>
              <Typography variant="body2" color="textSecondary" component="span">
                {formatCurrency(id)}
              </Typography>
            </>
          )}
        </MenuItem>
      ))}
    </Field>
  );
};

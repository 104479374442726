import { permissionsVar } from 'client/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { gql } from '__generated__/gql';

import { AllPerformanceCampaignGoalsFragmentData } from 'graphql/fragments';
import { CampaignStep, useGetOrderedCampaignsSteps } from './useGetOrderedCampaignsSteps';
import { APIPermission } from 'typeDeclarations/enums';
import { CampaignNode } from 'typeDeclarations/graphql/nodes';
import { isNull } from 'typeDeclarations/typeGuards';
import { StepStatus } from './CampaignContext/CampaignContext';

const STEP_INITIAL_STATUS: StepStatus = {
  touched: false,
  completed: false,
  hasBlockingIssues: false,
  hasNonBlockingIssues: false,
};

const CAMPAIGN_STEP_PERMISSION_MAP: PartialRecord<CampaignStep, APIPermission[]> = {
  ads: [APIPermission.ViewAds],
  budget: [APIPermission.ViewBudgets],
  keywords: [APIPermission.ViewKeywords],
  audiences: [APIPermission.ViewTargetingGroups],
  'ads-and-keywords': [APIPermission.ViewAds, APIPermission.ViewKeywords],
};

interface CampaignGoalQueryVariables {
  campaignUglyId?: string;
  campaignPrettyId?: string;
}

type CampaignGoalQueryData = AllPerformanceCampaignGoalsFragmentData & {
  campaign: null | Pick<CampaignNode, 'id' | 'goal' | 'modified' | 'conversionTrackingEnabled'>;
};

const CAMPAIGN_GOAL_QUERY = gql(/* GraphQL */ `
  query campaignGoal($campaignUglyId: ID, $campaignPrettyId: String) {
    ...AllCampaignGoalsFragment
    campaign(id: $campaignUglyId, _id: $campaignPrettyId) {
      id
      goal
      modified
      conversionTrackingEnabled
    }
  }
`);

export function useGenerateCampaignSteps({
  campaignUglyId,
  campaignPrettyId,
}: CampaignGoalQueryVariables): PartialRecord<CampaignStep, StepStatus> | undefined {
  const permissions = useReactiveVar(permissionsVar);
  const orderedSteps = useGetOrderedCampaignsSteps({ campaignPrettyId, campaignUglyId });

  const variables: CampaignGoalQueryVariables = {};

  if (campaignUglyId) {
    variables.campaignUglyId = campaignUglyId;
  } else {
    variables.campaignPrettyId = campaignPrettyId;
  }

  const { data, error } = useQuery<CampaignGoalQueryData, CampaignGoalQueryVariables>(CAMPAIGN_GOAL_QUERY, {
    variables,
  });

  if (error || !data) return undefined;

  const { campaign } = data;

  if (isNull(campaign)) {
    window.location.href = '/not-found';
    return undefined;
  }

  const steps: PartialRecord<CampaignStep, StepStatus> = {};

  orderedSteps.forEach((step) => {
    const stepPermissions = CAMPAIGN_STEP_PERMISSION_MAP[step];

    if (!stepPermissions || stepPermissions.every((permission) => Boolean(permissions[permission]))) {
      steps[step] = STEP_INITIAL_STATUS;
    }
  });

  return steps;
}

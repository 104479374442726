import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Collapse, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { FunctionComponent, useContext, useMemo, useState } from 'react';

import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { LocaleContext } from 'i18n/LocaleContext';
import { WalletPaymentSource } from './WalletPaymentSource/WalletPaymentSource';
import { ErrorMessage } from 'shared/ErrorMessage';
import { WalletMaxPaymentSources } from './WalletMaxPaymentSources/WalletMaxPaymentSources';
import { CreatePaymentSourceForm } from 'shared/PaymentSources/CreatePaymentSourceForm';
import { config } from 'appConfig';
import { logError } from 'utils/logging';
import { UserNode } from 'typeDeclarations/graphql/nodes';
import { Connection, Node } from 'typeDeclarations/graphql/base-types';
import { STRIPE_PAYMENT_SOURCES_PAGE_SIZE } from 'shared/PaymentSources/constants';
import { WalletPaymentSourceStripePaymentSourceData } from './WalletPaymentSource/fragments';
import { isError } from 'typeDeclarations/typeGuards';

const StyledCreatePaymentSourceForm = styled(CreatePaymentSourceForm)`
  &:last-child {
    margin-bottom: 0.25rem;
    border-radius: 0 0 4px 4px;
  }

  &.highlighted {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

// FIXME Should come from our Stripe library
function isStripeLocale(locale: string): locale is StripeElementLocale {
  const isLocale: PartialRecord<StripeElementLocale, boolean> = {
    es: true,
    en: true,
    pt: true,
    fr: true,
    nl: true,
    auto: true,
  };

  return locale in isLocale;
}

interface WalletPaymentSourcesQueryData {
  sessionUser: Pick<UserNode, 'id' | 'modified' | 'numStripeCardPaymentSources' | 'numStripeCardPaymentSourcesMax'>;
  sessionTeam: Node & {
    modified: string;
    billingProfile: Node & {
      modified: string;
      primaryPaymentSource:
        | null
        | (Node & {
            modified: string;
            stripePaymentSource: null | (Node & { modified: string } & WalletPaymentSourceStripePaymentSourceData);
          });
      otherStripeCardPaymentSources: Connection<
        Node & { modified: string } & WalletPaymentSourceStripePaymentSourceData
      >;
    };
  };
}

interface WalletPaymentSourcesQueryVariables {
  amount: number;
  cursor?: string | null;
}

const WALLET_PAYMENT_SOURCES_QUERY = gql(/* GraphQL */ `
  query walletPaymentSourcesQuery($amount: Int, $cursor: String) {
    sessionUser {
      id
      modified
      numStripeCardPaymentSources
      numStripeCardPaymentSourcesMax
    }
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        primaryPaymentSource {
          id
          modified
          stripePaymentSource {
            id
            modified
            ...WalletPaymentSourceStripePaymentSourceFragment
          }
        }
        otherStripeCardPaymentSources(first: $amount, after: $cursor, orderBy: "-created") {
          ...WalletPaymentSourcesStripePaymentSourcesConnectionFragment
        }
      }
    }
  }
`);

export const WalletPaymentSources: FunctionComponent = () => {
  const { formatMessage } = useExtendedIntl();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [managingCards, setManagingCards] = useState<boolean>(false);

  const toggleExpanded = () => setExpanded((prevState) => !prevState);
  const handleClose = () => setExpanded(false);
  const handleManageClick = () => setManagingCards((prevState) => !prevState);

  const { appLocale } = useContext(LocaleContext);

  // FIXME Should come from our Stripe library
  const slicedLocale = appLocale.slice(0, 2);

  let stripeLocale: StripeElementLocale = 'auto';

  if (isStripeLocale(slicedLocale)) {
    stripeLocale = slicedLocale;
  } else {
    logError(new Error(`locale ${slicedLocale} is not supported by Stripe`));
  }

  const stripePromise = useMemo(
    () =>
      loadStripe(config.stripeKey, { locale: stripeLocale }).catch((err) => {
        if (isError(err)) {
          logError(err, {
            tags: {
              context: 'Stripe',
            },
          });
        } else {
          logError(new Error(JSON.stringify(err)));
        }

        return null;
      }),
    [stripeLocale],
  );

  const {
    error,
    loading,
    data: queryData,
  } = useQuery<WalletPaymentSourcesQueryData, WalletPaymentSourcesQueryVariables>(WALLET_PAYMENT_SOURCES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      amount: STRIPE_PAYMENT_SOURCES_PAGE_SIZE,
    },
  });

  if (loading) return null;

  if (error || !queryData) return <ErrorMessage />;

  const { numStripeCardPaymentSources, numStripeCardPaymentSourcesMax } = queryData.sessionUser;

  const { primaryPaymentSource, otherStripeCardPaymentSources } = queryData.sessionTeam.billingProfile;

  const primaryStripePaymentSource = primaryPaymentSource?.stripePaymentSource;
  const paymentSources = otherStripeCardPaymentSources.edges.map(({ node }) => (
    <WalletPaymentSource key={node.id} paymentSource={node} handleClose={handleClose} managingCards={managingCards} />
  ));

  const header = (
    <Typography variant="subtitle2" fontWeight="bold">
      {formatMessage({ id: 'wallet-payment-sources.heading' })}
    </Typography>
  );

  const walletForm = (
    <Elements stripe={stripePromise}>
      <StyledCreatePaymentSourceForm
        setAsPrimary
        buttonSize="small"
        className={!primaryStripePaymentSource ? 'highlighted' : ''}
      />
    </Elements>
  );

  const cardLimitReached =
    numStripeCardPaymentSources !== null && numStripeCardPaymentSourcesMax !== null
      ? numStripeCardPaymentSources >= numStripeCardPaymentSourcesMax
      : true;

  const hasMultiplePaymentSources = paymentSources.length > 0;

  const collapseContent = (
    <Stack rowGap={2}>
      {hasMultiplePaymentSources && paymentSources}
      {cardLimitReached || managingCards ? (
        <WalletMaxPaymentSources
          onClick={handleManageClick}
          managingCards={managingCards}
          cardLimitReached={cardLimitReached}
        />
      ) : (
        walletForm
      )}
    </Stack>
  );

  if (!primaryStripePaymentSource) {
    return (
      <Stack rowGap={2}>
        {header}
        <Typography>
          {formatMessage({
            id: hasMultiplePaymentSources ? 'wallet-payment-sources.set-primary' : 'wallet-payment-sources.add-card',
          })}
        </Typography>
        {collapseContent}
      </Stack>
    );
  }

  return (
    <Stack rowGap={2}>
      {header}
      <WalletPaymentSource
        primary
        expanded={expanded}
        managingCards={managingCards}
        toggleExpanded={toggleExpanded}
        paymentSource={primaryStripePaymentSource}
      />
      <Collapse in={expanded} mountOnEnter>
        {collapseContent}
      </Collapse>
    </Stack>
  );
};

import { ApolloError, useApolloClient } from '@apollo/client';
import { gql } from '__generated__/gql';
import { setUser as setSentryUser } from '@sentry/react';
import { useEffect } from 'react';

import { logError } from 'utils/logging';
import { isError } from 'typeDeclarations/typeGuards';
import { UserNode } from 'typeDeclarations/graphql/nodes';
import { useUserTracking } from 'userTracking/useUserTracking';
import { canEnableIntercom } from 'shared/canEnableIntercom';
import { useIntercom } from 'react-use-intercom';

/**
 * Fragment and type created for the optimization purpose during the login process
 */
export interface UserUtilsBootQueryFragmentData {
  sessionUser: Pick<UserNode, 'id' | '_id' | 'ddtId' | 'name' | 'email' | 'theme'>;
}

export const USER_UTILS_BOOT_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment UserUtilsBootQueryFragment on Query {
    sessionUser {
      id
      _id
      ddtId
      name
      email
      theme
      modified
    }
  }
`);

type UserUtilsBootQueryData = UserUtilsBootQueryFragmentData;

const USER_UTILS_BOOT_QUERY = gql(/* GraphQL */ `
  query userUtilsBootQuery {
    ...UserUtilsBootQueryFragment
  }
`);

export function useUtilsBoot(): void {
  const client = useApolloClient();
  const { identifyUser } = useUserTracking();
  const { boot: bootIntercom, hardShutdown: shutdownIntercom } = useIntercom();

  useEffect(() => {
    client
      .query<UserUtilsBootQueryData>({ query: USER_UTILS_BOOT_QUERY })
      .then(({ data, errors }) => {
        if (errors) {
          throw new ApolloError({ graphQLErrors: errors });
        }

        const {
          sessionUser: { email, name: userName, _id: userPrettyID },
        } = data;

        /* TRACKING */
        identifyUser();

        /* SENTRY */
        setSentryUser({
          email,
          id: userPrettyID,
        });

        if (canEnableIntercom()) {
          bootIntercom({
            email,
            name: userName,
            userId: userPrettyID,
          });
        } else {
          shutdownIntercom();
        }
      })
      .catch((err) => {
        logError(isError(err) ? err : Error(JSON.stringify(err)));
      });
  }, [bootIntercom, client, identifyUser, shutdownIntercom]);
}

import { FunctionComponent, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { CreditCard as CreditCardIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { useSetPrimaryPaymentSource } from 'hooks/mutations/useSetPrimaryPaymentSource';
import { cardBrandMap } from 'utils/cardBrandMap';
import { EmptyState } from 'shared/EmptyState';
import { objectToClassName } from 'utils/objectToClassName';
import { DeletePaymentSourceButton } from 'shared/PaymentSources/DeletePaymentSourceButton/DeletePaymentSourceButton';
import { WalletPaymentSourceStripePaymentSourceData } from './fragments';
import { Typography, IconButton, Stack, Box } from '@mui/material';

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  transition: transform 0.2s;
  &.expanded {
    transform: rotate(180deg);
  }
`;

const StyledDeletePaymentSourceButton = styled(DeletePaymentSourceButton)`
  &.hidden {
    display: none;
  }
`;

interface WalletPaymentSourceProps {
  primary?: boolean;
  expanded?: boolean;
  managingCards: boolean;
  handleClose?: () => void;
  toggleExpanded?: () => void;
  paymentSource: WalletPaymentSourceStripePaymentSourceData;
}

export const WalletPaymentSource: FunctionComponent<PropsWithChildren<WalletPaymentSourceProps>> = ({
  handleClose,
  managingCards,
  paymentSource,
  toggleExpanded,
  primary = false,
  expanded = false,
}) => {
  const { formatDate, formatMessage } = useExtendedIntl();

  const { brand, lastDigits, isChargeable, expirationDate, id: paymentSourceId } = paymentSource;

  const { setPrimaryPaymentSource } = useSetPrimaryPaymentSource({ onCompleted: handleClose });

  const handleSetPrimary = () => setPrimaryPaymentSource(paymentSourceId);

  const isExpired = expirationDate ? new Date(expirationDate) < new Date() : false;
  const disabled = !primary && (!isChargeable || isExpired);
  const onClickHandler = primary ? toggleExpanded : handleSetPrimary;

  return (
    <>
      <Stack
        onClick={disabled || managingCards ? undefined : onClickHandler}
        sx={{
          padding: '0.5rem',
          cursor: 'pointer',
          borderRadius: '4px',
          transition: 'all 0.2s',
          border: '1px solid rgba(0, 0, 0, 0.12)',

          '&.primary': {
            borderWidth: '1.5px',
            borderColor: 'primary.main',
          },

          '&.secondary': {
            borderRadius: '.25rem',
            border: '1px solid rgba(0, 0, 0, 0.12)',

            '&:hover': {
              backgroundColor: 'action.hover',
            },
          },

          '&.expanded': {
            borderRadius: '.25rem',
          },

          '&.managing': {
            cursor: 'unset',

            '&:hover': {
              backgroundColor: 'unset',
            },
          },
        }}
      >
        <Stack direction="row" gap={2} alignItems="center">
          <Box
            sx={{
              borderRadius: '4px',
              border: '1.5px solid rgba(0, 0, 0, 0.08)',
              padding: '.5rem',

              '.MuiSvgIcon-root': {
                height: '2.5rem',
                width: 'auto',
              },
            }}
          >
            {(brand && cardBrandMap[brand]) ?? <EmptyState svg={CreditCardIcon} text="" />}
          </Box>
          <Stack>
            <Typography variant="subtitle2" fontWeight="bold">
              **** **** **** {lastDigits || '****'}
            </Typography>
            <Typography variant="body2" gap={2}>
              {`${formatMessage({ id: 'payment-method.expiration-date-label' })} ${
                expirationDate ? formatDate(new Date(expirationDate)) : '--'
              }`}
            </Typography>
          </Stack>
          <Box sx={{ justifySelf: 'flex-end', ml: 'auto' }}>
            {primary && !managingCards ? (
              <IconButton>
                <StyledArrowDropDownIcon className={objectToClassName({ expanded })} />
              </IconButton>
            ) : (
              <StyledDeletePaymentSourceButton
                iconButton
                paymentSourceId={paymentSourceId}
                className={objectToClassName({ hidden: !managingCards })}
              />
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

import { FunctionComponent, PropsWithChildren } from 'react';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { Grid, Button, Typography } from '@mui/material';

interface WalletMaxPaymentSourcesProps {
  onClick: () => void;
  managingCards: boolean;
  cardLimitReached: boolean;
}

export const WalletMaxPaymentSources: FunctionComponent<PropsWithChildren<WalletMaxPaymentSourcesProps>> = ({
  onClick,
  managingCards,
  cardLimitReached,
}) => {
  const { formatMessage } = useExtendedIntl();

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        p: '1rem',
        borderRadius: '0 0 4px 4px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      {cardLimitReached && (
        <Grid item xs={7}>
          <Typography variant="body2">{formatMessage({ id: 'create-payment-source-form.disabled' })}</Typography>
        </Grid>
      )}
      <Grid item container xs={cardLimitReached ? 5 : 12} justifyContent={cardLimitReached ? 'flex-end' : 'center'}>
        <Button onClick={onClick} variant="outlined" fullWidth={!cardLimitReached}>
          {managingCards
            ? formatMessage({ id: 'shared.done' })
            : formatMessage({ id: 'shared.manage-payment-sources' })}
        </Button>
      </Grid>
    </Grid>
  );
};

import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import {
  ListItemIcon,
  ListItemIconProps,
  MenuItem,
  MenuItemProps,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FunctionComponent, MouseEvent, ReactNode, useState, PropsWithChildren } from 'react';

import { ActionsMenu } from 'shared/ActionsMenu/ActionsMenu';

export interface Option extends MenuItemProps {
  hide?: boolean;
  submenu?: Option[];
  label: TypographyProps & { text: string };
  listIcon?: ListItemIconProps & { icon: ReactNode };
}

export const ActionsMenuItem: FunctionComponent<PropsWithChildren<Option>> = ({
  hide,
  submenu,
  listIcon,
  label: { text, ...typographyProps },
  ...menuItemProps
}) => {
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<null | HTMLElement>(null);

  const openActionsMenu = (event: MouseEvent<HTMLElement>) => {
    setActionsMenuAnchorEl(event.currentTarget);
  };

  if (hide) return null;

  if (submenu) {
    return (
      <>
        <MenuItem
          {...menuItemProps}
          onClick={openActionsMenu}
          key={`actions-menu-option-${text}`}
          sx={{ justifyContent: 'space-between' }}
        >
          <Stack direction="row">
            {listIcon && <ListItemIcon {...listIcon}>{listIcon.icon}</ListItemIcon>}
            <Typography {...typographyProps}>{text}</Typography>
          </Stack>
          <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
            <ArrowRightIcon />
          </ListItemIcon>
        </MenuItem>
        <ActionsMenu
          options={submenu}
          anchorEl={actionsMenuAnchorEl}
          onClose={() => setActionsMenuAnchorEl(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        />
      </>
    );
  }

  return (
    <MenuItem {...menuItemProps} key={`actions-menu-option-${text}`}>
      {listIcon && <ListItemIcon {...listIcon}>{listIcon.icon}</ListItemIcon>}
      <Typography {...typographyProps}>{text}</Typography>
    </MenuItem>
  );
};

import { FunctionComponent, ReactNode, PropsWithChildren } from 'react';
import { exists } from 'typeDeclarations/typeGuards';

import {
  Info as InfoIcon,
  Delete as DeleteIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { DefaultDialog, DefaultDialogProps } from 'components/shared/DefaultDialog/DefaultDialog';

import { alpha, Box, Stack, ButtonProps, useTheme, Typography } from '@mui/material';

type Variant = 'default' | 'info' | 'warning' | 'success' | 'delete';

type VariantOpts = {
  icon?: JSX.Element;
  iconColorBg?: string;
  secondaryButtonColor: ButtonProps['color'];
  primaryButtonColor: ButtonProps['color'];
};

interface MiniDialogProps extends DefaultDialogProps {
  loading?: boolean;
  variant?: Variant;
  onClick?: () => void;
  description?: ReactNode;
  subDescription?: ReactNode;
  primaryButtonText?: ReactNode;
  secondaryButtonText?: ReactNode;
}

export const MiniDialog: FunctionComponent<PropsWithChildren<MiniDialogProps>> = ({
  title,
  onClose,
  onClick,
  description,
  subDescription,
  loading = false,
  primaryButtonText,
  secondaryButtonText,
  variant = 'default',
  ...dialogProps
}) => {
  const { palette } = useTheme();

  const variantOpts: Record<Variant, VariantOpts> = {
    default: {
      primaryButtonColor: 'secondary',
      secondaryButtonColor: 'secondary',
    },
    info: {
      primaryButtonColor: 'secondary',
      secondaryButtonColor: 'secondary',
      iconColorBg: alpha(palette.info.main, 0.12),
      icon: <InfoIcon fontSize="small" color="info" />,
    },
    delete: {
      primaryButtonColor: 'error',
      secondaryButtonColor: 'error',
      iconColorBg: alpha(palette.error.main, 0.12),
      icon: <DeleteIcon fontSize="small" color="error" />,
    },
    warning: {
      primaryButtonColor: 'secondary',
      secondaryButtonColor: 'secondary',
      iconColorBg: alpha(palette.warning.main, 0.12),
      icon: <WarningIcon fontSize="small" color="warning" />,
    },
    success: {
      primaryButtonColor: 'secondary',
      secondaryButtonColor: 'secondary',
      iconColorBg: alpha(palette.success.main, 0.12),
      icon: <CheckCircleIcon fontSize="small" color="success" />,
    },
  };

  let dialogActionsProps: DefaultDialogProps['dialogActionsProps'];

  if (primaryButtonText || secondaryButtonText) {
    dialogActionsProps = {};

    if (secondaryButtonText) {
      dialogActionsProps.secondaryButtonProps = {
        onClick: onClose,
        disabled: loading,
        children: secondaryButtonText,
        color: variantOpts[variant].secondaryButtonColor,
      };
    }

    if (primaryButtonText) {
      dialogActionsProps.primaryButtonProps = {
        loading,
        onClick,
        children: primaryButtonText,
        color: variantOpts[variant].primaryButtonColor,
      };
    }
  }

  return (
    <DefaultDialog
      maxWidth="xs"
      onClose={onClose}
      disableEscapeKeyDown={loading}
      aria-labelledby="mini-dialog-title"
      dialogActionsProps={dialogActionsProps}
      aria-describedby="mini-dialog-description"
      {...dialogProps}
      title={
        <Stack direction="row" alignItems="center" columnGap={1}>
          {variantOpts[variant].icon && (
            <Box
              sx={{
                p: 0.5,
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '100%',
                alignSelf: 'flex-start',
                justifyContent: 'center',
                backgroundColor: variantOpts[variant].iconColorBg,
              }}
            >
              {variantOpts[variant].icon}
            </Box>
          )}
          {title}
        </Stack>
      }
      content={
        (exists(description) || exists(subDescription)) && (
          <>
            {exists(description) && (
              <Typography component="div" color="textSecondary" id="mini-dialog-description">
                {description}
              </Typography>
            )}
            {exists(subDescription) && (
              <Typography component="div" color="textSecondary" id="mini-dialog-sub-description">
                {subDescription}
              </Typography>
            )}
          </>
        )
      }
    />
  );
};

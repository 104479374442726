import { FunctionComponent, SVGProps, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

export interface DefaultThemeSVGProps {
  SVG: FunctionComponent<PropsWithChildren<SVGProps<SVGSVGElement>>>;
}

export const DefaultThemeSVG: FunctionComponent<PropsWithChildren<DefaultThemeSVGProps>> = ({ SVG }) => {
  const StyledThemeSVG = styled(SVG)(({ theme: { palette } }) => ({
    '*': { fill: palette.secondary.dark },
  }));

  return <StyledThemeSVG />;
};
